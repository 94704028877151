<bdsp-simple-page>

  <!-- Top Panel -->
  <div class="top-panel">
    <top-nav></top-nav>
  </div>

  <!-- Content Panel -->
  <div class="content-panel bds-bg-grays-gray-100">

    <!-- Content -->
    <div class="
      bds-p-64
      bds-flexbox
      bds-flex-row
      bds-justify-center">
      <div class="bds-text-jumbo">
        Sandbox
      </div>
    </div>

    <div class="ptl-container ptl-container-left">

      <div class="
        bds-grid
        ">
        <div class="
          bds-flexbox
          bds-flex-col
          bds-gap-8
          bds-items-center
          ptl-grid-item
          "
          [routerLink]="['/bwo']">
          <img
            src="assets/sandbox/bwo-ai-panel-prototype.png"
            alt="Screenshot of BWO Copilot MVP"
            width="200"
            class="
              bds-radius-medium
              bds-elevation-2"
          />
          <h2 class="bds-text-little">BWO Copilot MVP</h2>
        </div>
      </div>

    </div>
   
  </div>

</bdsp-simple-page>
import { Component, OnInit, ChangeDetectorRef } from '@angular/core'
import { LaunchDarklyService } from '@app/launchdarkly.service'

@Component({
  selector: 'banner-page',
  templateUrl: './banner.component.html',
})
export class BannerPageComponent implements OnInit {
  constructor(
    private cdr: ChangeDetectorRef,
    private ld: LaunchDarklyService,
  ) {}

  subscriptionForDevelopers
  showForDevelopers

  ngOnInit() {
    this.showForDevelopers = this.ld.flags['for-developers']
    this.cdr.detectChanges()
    this.subscriptionForDevelopers = this.ld.flagChange.subscribe(flags => {
      this.showForDevelopers = flags['for-developers'].current
    })
    this.ld.ldClient.on('ready', () => {
      this.showForDevelopers = this.ld.flags['for-developers']
    })
  }
}

import { HttpClient } from '@angular/common/http'
import { Inject, Injectable, InjectionToken } from '@angular/core'
import { environment } from 'src/environments/environment'
import { CmsServiceConfig } from '@app/shared/models/data.models'
import { Observable } from 'rxjs'

// Use injection token for ability to utilize depenency injection & multiple instances per component if needed
export const CMS_SERVICE_CONFIG = new InjectionToken<CmsServiceConfig>(
  'CmsServiceConfig',
)

@Injectable({
  providedIn: 'root',
})
export class CmsService<T> {
  protected readonly cmsUrl: string
  protected readonly cmsEndpoint: string

  constructor(
    protected httpClient: HttpClient,
    @Inject(CMS_SERVICE_CONFIG) config: CmsServiceConfig,
  ) {
    this.cmsUrl = environment.cmsUrl
    this.cmsEndpoint = config.cmsEndpoint
  }

  public getAllCmsItems(): Observable<T> {
    return this.httpClient.get<T>(`${this.cmsUrl}${this.cmsEndpoint}`).pipe()
  }

  public getCmsItemById(id: number): Observable<T> {
    return this.httpClient
      .get<T>(`${this.cmsUrl}${this.cmsEndpoint}/${id}`)
      .pipe()
  }
}

import { Injectable } from '@angular/core'
import { Subject } from 'rxjs'
import { initialize, LDClient, LDFlagSet } from 'launchdarkly-js-client-sdk'
import { environment } from '@env/environment'

@Injectable()
export class LaunchDarklyService {
  ldClient: LDClient
  flags: LDFlagSet
  flagChange: Subject<Object> = new Subject<Object>()

  constructor() {
    this.flags = {
      'for-developers': false,
      environment: 'dev',
    }

    this.ldClient = initialize(
      environment.ldCsId,
      {
        kind: 'user',
        anonymous: true,
      },
      { bootstrap: 'localStorage' },
    )

    this.ldClient.on('ready', () => {
      this.setFlags()
    })

    this.ldClient.on('change', flags => {
      if (flags['for-developers'] !== undefined) {
        this.flags['for-developers'] = flags['for-developers']
      }
      if (flags['environment'] !== undefined) {
        this.flags['environment'] = flags['environment']
      }
      this.flagChange.next(this.flags)
    })
  }

  setFlags() {
    this.flags = this.ldClient.allFlags()
  }
}

import { Injectable } from '@angular/core'
import { BehaviorSubject } from 'rxjs'

@Injectable({
  providedIn: 'root',
})
export class ScrollService {
  currentSection: BehaviorSubject<string> = new BehaviorSubject('#Definition')

  sections: string[] = [
    '#Definition',
    '#Status',
    '#Canvas',
    '#UsageGuidelines',
    '#Behavior',
    '#BestPractices',
    '#UsageExample',
    '#ContentGuidelines',
    '#Accessibility',
    '#Related',
  ]

  constructor() {
    document.addEventListener('scroll', () => {
      this.emitCurrentScrollRegion()
    })
  }

  emitCurrentScrollRegion() {
    const viewHeight = window.innerHeight
    for (const section of this.sections) {
      const element = document.getElementById(section)
      if (element.id != null) {
        const rect = element.getBoundingClientRect()
        if (rect.top >= 70 && rect.top < viewHeight / 5) {
          this.currentSection.next(section)
        }
      }
    }
  }
}

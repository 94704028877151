import { Component, Inject, OnInit } from '@angular/core'
import { Title } from '@angular/platform-browser'
import {
  Router,
  NavigationEnd,
  ActivatedRoute,
  NavigationStart,
} from '@angular/router'
import { trigger, style, animate, transition } from '@angular/animations'

import { filter } from 'rxjs/operators'

import { LaunchDarklyService } from '@app/launchdarkly.service'
import { CmsResponseModel } from './shared/models/data.models'
import { CmsService } from './shared/services/cms.service'
import { HttpClient } from '@angular/common/http'
import { GlobalCmsResponseManager } from '../global/global_cms_manager'

@Component({
  selector: 'rie-root',
  templateUrl: './rie.component.html',
  styleUrls: ['./rie.component.scss'],
  providers: [
    LaunchDarklyService,
    {
      provide: 'cmsService',
      useFactory: (http: HttpClient) =>
        new CmsService<CmsResponseModel>(http, {
          cmsEndpoint: '/umbraco/delivery/api/v1/content',
        }),
      deps: [HttpClient],
    },
  ],
  animations: [
    trigger('showHide', [
      transition(':leave', [
        style({ opacity: 1 }),
        animate('600ms ease-in-out', style({ opacity: 0 })),
      ]),
    ]),
  ],
})
export class RieComponent implements OnInit {
  global_cms_manager: GlobalCmsResponseManager
  title = 'BDS Reference'
  showLoader = true
  subscriptionForEnvironment

  constructor(
    public router: Router,
    private activatedRoute: ActivatedRoute,
    private titleService: Title,
    private ld: LaunchDarklyService,
    @Inject('cmsService') private cmsService: CmsService<CmsResponseModel>,
    _global_cms_manager: GlobalCmsResponseManager,
  ) {
    this.global_cms_manager = _global_cms_manager
  }

  ngOnInit() {
    this.router.events.pipe(filter(event => event instanceof NavigationStart))
    this.router.events
      .pipe(filter(event => event instanceof NavigationEnd))
      .subscribe(() => {
        const rt = this.getChild(this.activatedRoute)
        rt.data.subscribe(data => {
          this.titleService.setTitle(data.title)
        })
      })
    this.ld.ldClient.on('ready', () =>
      setTimeout(() => {
        this.showLoader = false
      }, 1250),
    )
    // Call CMS service to interface with CMS API
    this.cmsService.getAllCmsItems().subscribe(
      (response: CmsResponseModel) => {
        this.global_cms_manager.__SET_GLOBAL_CMS_RESPONSE__(response)
        console.log(
          'data: ',
          this.global_cms_manager.__GET_GLOBAL_CMS_RESPONSE__(),
        )
      },
      error => {
        console.error('Error during GET call to CMS: ', error)
      },
    )
  }

  getChild(activatedRoute: ActivatedRoute) {
    if (activatedRoute.firstChild) {
      return this.getChild(activatedRoute.firstChild)
    } else {
      return activatedRoute
    }
  }
}

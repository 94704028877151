import { Component, HostListener, ViewChild } from '@angular/core'
import { HrbModal } from '@bds/bds-ng'

@Component({
  selector: 'mobile-menu',
  templateUrl: './mobile-menu.component.html',
  styleUrls: ['./mobile-menu.component.scss'],
})
export class MobileMenuComponent {
  mobileMenu = {
    topMenu: true,
    getStarted: false,
    foundations: false,
    components: false,
    patterns: false,
    support: false,
  }

  @ViewChild('MobileMenu') menu!: HrbModal

  @HostListener('hrbClose', ['$event'])
  onClose($event) {
    if ($event.target.id === 'portal-mobile-menu') this.hideMobileSubnav()
  }

  hideMobileSubnav() {
    Object.keys(this.mobileMenu).forEach(x => {
      this.mobileMenu[x] = false
    })
    this.mobileMenu['topMenu'] = true
  }

  showMobileSubnav(text: string) {
    Object.keys(this.mobileMenu).forEach(x => {
      this.mobileMenu[x] = false
    })
    this.mobileMenu[text] = true
  }
}

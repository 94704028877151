<div class="side-nav-links bds-my-24">
  <hrb-side-nav-item
    [attr.data-state]="currentRoute === 'components' && 'selected'"
    [routerLink]="['/components']">Overview</hrb-side-nav-item>
  <hrb-side-nav-item
    [attr.data-state]="currentRoute === 'accordion' && 'selected'"
    [routerLink]="['/accordion']">Accordion</hrb-side-nav-item>
  <hrb-side-nav-item
    [attr.data-state]="currentRoute === 'banner' && 'selected'"
    [routerLink]="['/banner']">Banner</hrb-side-nav-item>
  <hrb-side-nav-item
    [attr.data-state]="currentRoute === 'button' && 'selected'"
    [routerLink]="['/button']">Button</hrb-side-nav-item>
  <hrb-side-nav-item
    [attr.data-state]="currentRoute === 'card' && 'selected'"
    [routerLink]="['/card']">Card</hrb-side-nav-item>
  <hrb-side-nav-item
    [attr.data-state]="currentRoute === 'checkbox' && 'selected'"
    [routerLink]="['/checkbox']">Checkbox</hrb-side-nav-item>
  <hrb-side-nav-item
    [attr.data-state]="currentRoute === 'input' && 'selected'"
    [routerLink]="['/input']">Input</hrb-side-nav-item>
  <hrb-side-nav-item
    [attr.data-state]="currentRoute === 'label' && 'selected'"
    [routerLink]="['/label']">Label</hrb-side-nav-item>
  <hrb-side-nav-item
    [attr.data-state]="currentRoute === 'link' && 'selected'"
    [routerLink]="['/link']">Link</hrb-side-nav-item>
  <hrb-side-nav-item
    [attr.data-state]="currentRoute === 'radio' && 'selected'"
    [routerLink]="['/radio']">Radio</hrb-side-nav-item>
  <hrb-side-nav-item
    [attr.data-state]="currentRoute === 'select' && 'selected'"
    [routerLink]="['/select']">Select</hrb-side-nav-item>
  <hrb-side-nav-item
    [attr.data-state]="currentRoute === 'textarea' && 'selected'"
    [routerLink]="['/textarea']">Textarea</hrb-side-nav-item>
  <hrb-side-nav-item
    [attr.data-state]="currentRoute === 'toast' && 'selected'"
    [routerLink]="['/toast']">Toast</hrb-side-nav-item>
  <hrb-side-nav-item
    [attr.data-state]="currentRoute === 'toggle' && 'selected'"
    [routerLink]="['/toggle']">Toggle</hrb-side-nav-item>
</div>

<hrb-rule *ngIf="showForDevelopers"></hrb-rule>

<div class="side-nav-links bds-mt-24">
  <hrb-side-nav-item
    *ngIf="showForDevelopers"
    [attr.data-state]="currentRoute === 'component-details' && 'selected'"
    [routerLink]="['/component-details']">Component Details</hrb-side-nav-item>
</div>
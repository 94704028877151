<bdsp-standard-page>

  <!-- Top Panel -->
  <div class="top-panel">
    <top-nav></top-nav>
  </div>

  <!-- Left Panel -->
  <div class="left-panel">
    <side-nav></side-nav>
  </div>

  <!-- Content Panel -->
  <div class="content-panel">

    <!-- Content Definition -->
    <content-definition
      id="#Definition"
      class="scroll-region">
      <div class="component-name">Card</div>
      <div class="component-tag-name">&lt;hrb-card&gt;</div>
      <div class="component-definition">Card is a container that holds other components and elements within it.</div>
      <div class="component-figma-link">
        <hrb-link target="_blank" href="https://www.figma.com/design/SjIweQxQcqDzUxbSEiyiQr/%F0%9F%9F%A9-Block-Design-System?m=auto&node-id=7076-171379&t=pazvl9a8B8pVR4ew-1">Open in Figma</hrb-link>
      </div>
      <div class="component-storybook-link">
        <hrb-link href="https://a3pcbdsstr02t.z19.web.core.windows.net/?path=/story/components-cards-base-card--base-card" target="_blank">Open in Storybook</hrb-link>
      </div>
    </content-definition>

    <hrb-rule></hrb-rule>

    <div class="
      bds-pt-64
      ptl-container
      ptl-container-left">

      <bdsp-content-columns>
        
        <div class="content">

          <!-- Status -->
          <section
            id="#Status"
            class="
              scroll-region
              bds-pb-48
              bds-flexbox
              bds-flex-col
              bds-gap-24">
            <h2 class="bds-text-small">Status</h2>

            <div>
              <div class="labels bds-flexbox bds-flex-row bds-flex-wrap bds-gap-24 bds-mt-16">
                <div class="bds-flexbox bds-gap-8 bds-items-center">
                  <span class="bds-text-little">bds-figma:</span>
                  <hrb-label>Ready for use</hrb-label>
                </div>
                <div class="bds-flexbox bds-gap-8 bds-items-center">
                  <span class="bds-text-little">bds-core:</span>
                  <hrb-label theme="neon-hrb">In progress</hrb-label>
                </div>
              </div>
            </div>
          </section>

          <!-- Canvas -->
          <section
            id="#Canvas"
            class="
              scroll-region
              bds-pb-48
              bds-flex
              bds-flex-col
              bds-gap-24">
            <h2 class="bds-text-small">Canvas</h2>
            <div class="bds-bg-grays-gray-100 bds-p-64 bds-radius-medium bds-flexbox bds-justify-center">
              <div class="canvas bds-flexbox bds-flex-col bds-gap-16 bds-justify-center bds-items-center">
                    <hrb-card></hrb-card>
                </div>
            </div>
          </section>

          <!-- Usage Guidelines -->
          <section
            id="#UsageGuidelines"
            class="
              scroll-region
              bds-pb-48
              bds-flexbox
              bds-flex-col
              bds-gap-24">
            <h2 class="bds-text-small">Usage Guidelines</h2> 
            <p class="bds-text-body-regular">The card component is a generic container component with 24px padding. At this time, BDS does not define specific card layouts. Use a card to present related information on a single topic. Cards can be used as a teaser of an experience to follow. For example, a card that displays a simple message with no destination.</p>
          </section>

          <!-- Behavior -->
          <section
            id="#Behavior"
            class="
              scroll-region
              bds-pb-48
              bds-flexbox
              bds-flex-col
              bds-gap-24">
            <h2 class="bds-text-small">Behavior</h2>
            <p class="bds-text-body-regular">The behavior of a card will depend on its context. Cards can exist alone, in a grid, a carousel, and more. Usually, a card takes users to a single destination or allows for a single action.</p>
          </section>
          
          <!-- Best Practices -->
          <section
            id="#BestPractices"
            class="
              scroll-region
              bds-pb-48
              bds-flexbox
              bds-flex-col
              bds-gap-24">
            <h2 class="bds-text-small">Best Practices</h2>
            <div class="bds-flexbox bds-flex-col bds-flex-row_md bds-gap-24">
              <div class="bds-flexbox bds-flex-col bds-gap-24 bds-flex-1">
                <h3 class="bds-text-small bds-text-global-green">Dos</h3>
                <div class="bds-bg-grays-gray-100 bds-radius-medium">
                  <hrb-img
                    alt="A card with content that has proper padding"
                    fit
                    src="assets/component-details/card/card-do.png"></hrb-img>
                </div>
                <ul class="bds-text-body-regular hrb-text--bullet-list bds-pl-24">
                  <li class="bds-body-regular">
                    Maintain clear visual hierarchy when designing the layout of a card.
                  </li>
                  <li class="bds-body-regular">
                    Align the card to the screen's grid.
                  </li>
                </ul>
              </div>
              <div class="bds-flexbox bds-flex-col bds-gap-24 bds-flex-1">
                <h3 class="bds-text-small bds-text-reds-red-400">Don'ts</h3>
                <div class="bds-bg-grays-gray-100 bds-radius-medium">
                  <hrb-img
                    fit
                    alt="A card with content that has improper padding"
                    src="assets/component-details/card/card-dont.png"></hrb-img>
                </div>
                <ul class="bds-text-body-regular hrb-text--bullet-list bds-pl-24">
                  <li class="bds-body-regular">
                    Allow content to overflow into the 24px padding on all four sides.
                  </li>
                  <li class="bds-body-regular">
                    Use an alternate border radius value.
                  </li>
                </ul>
              </div>
            </div>
          </section>

          <!-- Usage Example -->
          <section
            id="#UsageExample"
            class="
              scroll-region
              bds-pb-48
              bds-flexbox
              bds-flex-col
              bds-gap-24">
            <h2 class="bds-text-small">Usage Example</h2>
            <div class="bds-bg-grays-gray-200 bds-p-32 bds-radius-medium bds-flexbox bds-flex-col bds-gap-32">
              <div class="bds-flexbox bds-flex-col bds-flex-row_lg bds-gap-32 bds-flex-1">
                <div>
                  <h3 class="bds-text-little bds-mb-16">Plain card</h3>
                  <hrb-img
                    fit
                    alt="A default card"
                    src="assets/component-details/card/card.png"></hrb-img>
                </div>
                <div>
                  <h3 class="bds-text-little bds-mb-16">Card with shadow</h3>
                  <hrb-img
                    fit
                    alt="A card with a drop shadow"
                    src="assets/component-details/card/card-with-shadow.png"></hrb-img>
                </div>
              </div>
              <div class="bds-flexbox bds-flex-col bds-flex-row_lg bds-gap-32 bds-flex-1">
                <div>
                  <h3 class="bds-text-little bds-mb-16">Card with hat</h3>
                  <hrb-img
                    fit
                    alt="A card with a hat"
                    src="assets/component-details/card/card-with-hat.png"></hrb-img>
                </div>
                <div>
                  <h3 class="bds-text-little bds-mb-16">Card with boot</h3>
                  <hrb-img
                    fit
                    alt="A card with a boot"
                    src="assets/component-details/card/card-with-boot.png"></hrb-img>
                </div>
              </div>
            </div>
          </section>

          <!-- Accessibility -->
          <section
            id="#Accessibility"
            class="
              scroll-region
              bds-pb-48
              bds-flexbox
              bds-flex-col
              bds-gap-24">
            <h2 class="bds-text-small">Accessibility</h2>
            <p class="bds-text-body-regular">All text, elements and components must meet accessibility standards. The Web Content Accessibility Guidelines (WCAG 2.2) level AA requires a 4.5:1 color contrast ratio between text and background color for normal text, and 3:1 for large text. Icons and other critical elements should meet this same level of contrast.</p>
          </section>

          <!-- Related -->
          <section
            id="#Related"
            class="
              scroll-region
              bds-pb-48
              bds-flexbox
              bds-flex-col
              bds-gap-24">
              <h2 class="bds-text-small">Related</h2>
              <hrb-link [routerLink]="['/coming-soon']">Modal</hrb-link>
          </section>
        </div>

        <!-- TOC -->
        <div class="context">
          <toc></toc>
        </div>
        
      </bdsp-content-columns>

    </div>

    <bdsp-footer></bdsp-footer>
   
  </div>

</bdsp-standard-page>
import { Component } from '@angular/core'
import { Router } from '@angular/router'

@Component({
  selector: 'rie-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss'],
})
export class HomeComponent {
  mobileMenu = {
    topMenu: true,
    getStarted: false,
    foundations: false,
    components: false,
    patterns: false,
    support: false,
  }

  constructor(private router: Router) {}

  navigate(route: string) {
    this.router.navigate([route])
  }

  hideMobileSubnav() {
    Object.keys(this.mobileMenu).forEach(x => {
      this.mobileMenu[x] = false
    })
    this.mobileMenu['topMenu'] = true
  }

  showMobileSubnav(text: string) {
    Object.keys(this.mobileMenu).forEach(x => {
      this.mobileMenu[x] = false
    })
    this.mobileMenu[text] = true
  }
}

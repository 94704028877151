import { NgModule } from '@angular/core'
import { Routes, RouterModule } from '@angular/router'

/*******************************/
/************ MVP **************/

// Home
import { HomeComponent } from './home/home.component'

// Components
import { ComponentsComponent } from './components/components.component'
import { ButtonPageComponent } from './components/button/button.component'
import { InputPageComponent } from './components/input/input.component'
import { CardPageComponent } from './components/card/card.component'
import { SelectPageComponent } from './components/select/select.component'
import { TextareaPageComponent } from './components/textarea/textarea.component'
import { RadioPageComponent } from './components/radio/radio.component'
import { CheckboxPageComponent } from './components/checkbox/checkbox.component'
import { BannerPageComponent } from './components/banner/banner.component'
import { AccordionPageComponent } from './components/accordion/accordion.component'
import { ToastPageComponent } from './components/toast/toast.component'
import { LinkPageComponent } from './components/link/link.component'
import { TogglePageComponent } from './components/toggle/toggle.component'
import { LabelPageComponent } from './components/label/label.component'

// Patterns
import { SimplePageComponent } from './patterns/simple-page/simple-page.component'
import { StandardPageComponent } from './patterns/standard-page/standard-page.component'

// Shared
import { ComingSoonComponent } from './shared/coming-soon/coming-soon.component'

// In Development
import { ComponentDetailsComponent } from './components/component-details/component-details.component'
import { ShowcaseComponent } from './showcase/showcase.component'
import { CopilotComponent } from './copilot/copilot.component'
import { SandboxComponent } from './sandbox/sandbox.component'
import { BwoSandboxComponent } from './sandbox/bwo/bwo.component'

const routes: Routes = [
  {
    path: 'home',
    component: HomeComponent,
    data: { title: 'Home' },
  },
  {
    path: 'components',
    component: ComponentsComponent,
    data: { title: 'Components' },
  },
  {
    path: 'component-details',
    component: ComponentDetailsComponent,
    data: { title: 'Component Details' },
  },
  {
    path: 'button',
    component: ButtonPageComponent,
    data: { title: 'Component: Button' },
  },
  {
    path: 'input',
    component: InputPageComponent,
    data: { title: 'Component: Input' },
  },
  {
    path: 'card',
    component: CardPageComponent,
    data: { title: 'Component: Card' },
  },
  {
    path: 'select',
    component: SelectPageComponent,
    data: { title: 'Component: Select' },
  },
  {
    path: 'textarea',
    component: TextareaPageComponent,
    data: { title: 'Component: Textarea' },
  },
  {
    path: 'radio',
    component: RadioPageComponent,
    data: { title: 'Component: Radio' },
  },
  {
    path: 'checkbox',
    component: CheckboxPageComponent,
    data: { title: 'Component: Radio' },
  },
  {
    path: 'banner',
    component: BannerPageComponent,
    data: { title: 'Component: Banner' },
  },
  {
    path: 'accordion',
    component: AccordionPageComponent,
    data: { title: 'Component: Accordion' },
  },
  {
    path: 'toast',
    component: ToastPageComponent,
    data: { title: 'Component: Toast' },
  },
  {
    path: 'link',
    component: LinkPageComponent,
    data: { title: 'Component: Link' },
  },
  {
    path: 'toggle',
    component: TogglePageComponent,
    data: { title: 'Component: Toggle' },
  },
  {
    path: 'label',
    component: LabelPageComponent,
    data: { title: 'Component: Label' },
  },
  {
    path: 'simple-page',
    component: SimplePageComponent,
    data: { title: 'Pattern: Simple Page' },
  },
  {
    path: 'standard-page',
    component: StandardPageComponent,
    data: { title: 'Pattern: Standard Page' },
  },
  {
    path: 'showcase',
    component: ShowcaseComponent,
    data: { title: 'Showcase: Home' },
  },
  {
    path: 'copilot',
    component: CopilotComponent,
    data: { title: 'Co-pilot: Home' },
  },
  {
    path: 'sandbox',
    component: SandboxComponent,
    data: { title: 'Sandbox: Home' },
  },
  {
    path: 'bwo',
    component: BwoSandboxComponent,
    data: { title: 'Sandbox: BWO' },
  },
  {
    path: 'coming-soon',
    component: ComingSoonComponent,
    data: { title: 'Coming Soon' },
  },

  /* CATCH-ALL */
  { path: '**', component: HomeComponent },
]

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      useHash: true,
      anchorScrolling: 'enabled',
      scrollPositionRestoration: 'enabled',
    }),
  ],
  exports: [RouterModule],
})
export class RieRoutingModule {}

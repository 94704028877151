import { Component, OnInit, ChangeDetectorRef } from '@angular/core'
import { ActivatedRoute } from '@angular/router'
import { map } from 'rxjs/operators'
import { LaunchDarklyService } from '@app/launchdarkly.service'

@Component({
  selector: 'side-nav',
  templateUrl: './side-nav.component.html',
  providers: [LaunchDarklyService],
})
export class SideNavComponent implements OnInit {
  constructor(
    route: ActivatedRoute,
    private cdr: ChangeDetectorRef,
    private ld: LaunchDarklyService,
  ) {
    this.url = route.url.pipe(map(segments => segments.join('')))
  }

  subscriptionForDevelopers
  showForDevelopers
  url
  currentRoute

  ngOnInit(): void {
    this.showForDevelopers = this.ld.flags['for-developers']
    this.cdr.detectChanges()
    this.subscriptionForDevelopers = this.ld.flagChange.subscribe(flags => {
      this.showForDevelopers = flags['for-developers'].current
    })
    this.ld.ldClient.on('ready', () => {
      this.showForDevelopers = this.ld.flags['for-developers']
    })

    this.url.subscribe(value => (this.currentRoute = value))
  }
}

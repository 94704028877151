<bdsp-simple-page>

  <!-- Top Panel -->
  <div class="top-panel">
    <top-nav></top-nav>
  </div>

  <!-- Content Panel -->
  <div class="content-panel">

    <!-- Home Content -->
    <div class="home-hero bds-bg-global-sand bds-mb-64">
        <div class="bds-container bds-container-center bds-flexbox bds-flex-col bds-gap-48 bds-justify-center bds-align-center">
            <h1>
                <span class='home-headline'>
                    It&rsquo;s Better<br>With 
                    <span class='home-headline-punch'>Block</span>
                </span>
                <span class='home-headline-script'>Design System</span>
            </h1>
            <div class="home-hero-actions">
                <hrb-button [routerLink]="['/components']" full-width>Go to Components</hrb-button>
            </div>
        </div>
    </div>

    <div class="bds-container bds-container-center bds-flexbox bds-flex-col bds-gap-64">

        <!-- Green Box SECTION -->
        <section class="bds-grid bds-gap-32">
            <div
                [routerLink]="['/components']"
                class="
                    home-green-card
                    bds-col-span-6_md
                    bds-col-span-12_sm
                    bds-bg-global-green
                    bds-radius-medium
                    bds-text-grays-white">
                <div class="bds-p-32 bds-flexbox bds-gap-32">
                    <div>
                        <hrb-illustration alt="A stack of numbered blocks" width="142" data-name="new-baby"></hrb-illustration>
                    </div>
                    <div class="bds-flexbox bds-flex-col bds-gap-16">
                        <h2 class="bds-text-small">Components</h2>
                        <p class="bds-text-body-regular">Components are reusable design elements. They help us build consistency across our websites and products.</p>
                        <hrb-link [routerLink]="['/components']" theme="white" icon="chevron-right">View components</hrb-link>
                    </div>
                </div>
            </div>
            <div
                [routerLink]="['/coming-soon']"
                class="
                    home-green-card
                    bds-col-span-6_md
                    bds-col-span-12_sm
                    bds-bg-global-green
                    bds-radius-medium
                    bds-text-grays-white">
                <div class="bds-p-32 bds-flexbox bds-gap-32">
                    <div>
                        <hrb-illustration alt="A cardboard box with a green cube inside" width="142" data-name="moving"></hrb-illustration>
                    </div>
                    <div class="bds-flexbox bds-flex-col bds-gap-16">
                        <h2 class="bds-text-small">Patterns</h2>
                        <p class="bds-text-body-regular">Patterns are two or more components that build up an experience. They offer us repeatable solutions to common design problems.</p>
                        <hrb-link theme="white" icon="chevron-right">View patterns</hrb-link>
                    </div>
                </div>
            </div>
        </section>
        
        <!-- Category SECTION(s) -->
        <section class="bds-grid bds-gap-24">
            <div class="bds-col-span-3_md bds-col-span-12_sm bds-flexbox bds-flex-col bds-gap-24 bds-justify-center">
                <h2 class="bds-text-small">Get Started</h2>
                <p class="bds-text-body-regular">Recommended resources and guides to get you started.</p>
            </div>
            <div
                [routerLink]="['/coming-soon']"
                class="bds-col-span-3_md bds-col-span-12_sm bds-flexbox bds-flex-col bds-gap-24">
                <div class="home-media-card-image home-coming-soon bds-flexbox bds-justify-center bds-py-24 bds-bg-global-sand bds-radius-medium">
                    <hrb-illustration data-name="peace-of-mind"></hrb-illustration>
                    <p class="home-coming-soon-tag bds-bg-grays-black bds-pt-4 bds-text-little bds-text-grays-white">Coming soon!</p>
                </div>
                <div class="bds-flexbox bds-flex-col bds-gap-8">
                    <h2 class="bds-text-little">Design</h2>
                    <p class="bds-text-body-regular">Portal has everything you need to get started designing great experiences at Block. </p>
                </div>
            </div>
            <div
                [routerLink]="['/coming-soon']"
                class="bds-col-span-3_md bds-col-span-12_sm bds-flexbox bds-flex-col bds-gap-24">
                <div class="home-media-card-image home-coming-soon bds-flexbox bds-justify-center bds-py-24 bds-bg-global-sand bds-radius-medium">
                    <hrb-illustration data-name="help-and-resources"></hrb-illustration>
                    <p class="home-coming-soon-tag bds-bg-grays-black bds-pt-4 bds-text-little bds-text-grays-white">Coming soon!</p>                </div>
                <div class="bds-flexbox bds-flex-col bds-gap-8">
                    <h2 class="bds-text-little">Develop</h2>
                    <p class="bds-text-body-regular">A great resource to get you up and running as a developer.</p>
                </div>
            </div>
            <div
                [routerLink]="['/coming-soon']"
                class="bds-col-span-3_md bds-col-span-12_sm bds-flexbox bds-flex-col bds-gap-24">
                <div class="home-media-card-image home-coming-soon bds-flexbox bds-justify-center bds-py-24 bds-bg-global-sand bds-radius-medium">
                    <hrb-illustration data-name="real-time-results"></hrb-illustration>
                    <p class="home-coming-soon-tag bds-bg-grays-black bds-pt-4 bds-text-little bds-text-grays-white">Coming soon!</p>
                </div>
                <div class="bds-flexbox bds-flex-col bds-gap-8">
                    <h2 class="bds-text-little">Content</h2>
                    <p class="bds-text-body-regular">Get to know the voice and tone of our one of a kind brand. </p>
                </div>
            </div>
        </section>
        
        <section class="bds-grid bds-gap-24">
            <div class="bds-col-span-3_md bds-col-span-12_sm bds-flexbox bds-flex-col bds-gap-24 bds-justify-center">
                <h2 class="bds-text-small">Styles</h2>
                <hrb-text>Discover how we create unique brand experiences.</hrb-text>
            </div>
            <div
                [routerLink]="['/coming-soon']"
                class="bds-col-span-3_md bds-col-span-12_sm bds-flexbox bds-flex-col bds-gap-24">
                <div class="home-media-card-image home-coming-soon bds-flexbox bds-justify-center bds-py-24 bds-bg-global-sand bds-radius-medium">
                    <hrb-illustration data-name="accuracy-review"></hrb-illustration>
                    <p class="home-coming-soon-tag bds-bg-grays-black bds-pt-4 bds-text-little bds-text-grays-white">Coming soon!</p>
                </div>
                <div class="bds-flexbox bds-flex-col bds-gap-8">
                    <h2 class="bds-text-little">Colors</h2>
                    <p class="bds-text-body-regular">Check out the colors that make up our brand and products.</p>
                </div>
            </div>
            <div
                [routerLink]="['/coming-soon']"
                class="bds-col-span-3_md bds-col-span-12_sm bds-flexbox bds-flex-col bds-gap-24">
                <div class="home-media-card-image home-coming-soon bds-flexbox bds-justify-center bds-py-24 bds-bg-global-sand bds-radius-medium">
                    <hrb-illustration data-name="sunshine-1"></hrb-illustration>
                    <p class="home-coming-soon-tag bds-bg-grays-black bds-pt-4 bds-text-little bds-text-grays-white">Coming soon!</p>
                </div>
                <div class="bds-flexbox bds-flex-col bds-gap-8">
                    <h2 class="bds-text-little">Brand</h2>
                    <p class="bds-text-body-regular">View our core mission, company vision, and branding principals.</p>
                </div>
            </div>
            <div
                [routerLink]="['/coming-soon']"
                class="bds-col-span-3_md bds-col-span-12_sm bds-flexbox bds-flex-col bds-gap-24">
                <div class="home-media-card-image home-coming-soon bds-flexbox bds-justify-center bds-py-24 bds-bg-global-sand bds-radius-medium">
                    <hrb-illustration data-name="search-subscription"></hrb-illustration>
                    <p class="home-coming-soon-tag bds-bg-grays-black bds-pt-4 bds-text-little bds-text-grays-white">Coming soon!</p>
                </div>
                <div class="bds-flexbox bds-flex-col bds-gap-8">
                    <h2 class="bds-text-little">Themes</h2>
                    <p class="bds-text-body-regular">Learn about our different brands and their unique stylistic features.</p>
                </div>
            </div>
        </section>
        
        <section class="bds-grid bds-gap-24">
            <div class="bds-col-span-3_md bds-col-span-12_sm bds-flexbox bds-flex-col bds-gap-24 bds-justify-center">
                <h2 class="bds-text-small">Support</h2>
                <hrb-text>Find answers to all your questions.</hrb-text>
            </div>
            <div
                [routerLink]="['/coming-soon']"
                class="bds-col-span-3_md bds-col-span-12_sm bds-flexbox bds-flex-col bds-gap-24">
                <div class="home-media-card-image home-coming-soon bds-flexbox bds-justify-center bds-py-24 bds-bg-global-sand bds-radius-medium">
                    <hrb-illustration data-name="hrb-office"></hrb-illustration>
                    <p class="home-coming-soon-tag bds-bg-grays-black bds-pt-4 bds-text-little bds-text-grays-white">Coming soon!</p>
                </div>
                <div class="bds-flexbox bds-flex-col bds-gap-8">
                    <h2 class="bds-text-little">FAQs</h2>
                    <p class="bds-text-body-regular">Browse our frequently asked questions to find the answers you need.</p>
                </div>
            </div>
            <div
                [routerLink]="['/coming-soon']"
                class="bds-col-span-3_md bds-col-span-12_sm bds-flexbox bds-flex-col bds-gap-24">
                <div class="home-media-card-image home-coming-soon bds-flexbox bds-justify-center bds-py-24 bds-bg-global-sand bds-radius-medium">
                    <hrb-illustration data-name="help-me-choose-1"></hrb-illustration>
                    <p class="home-coming-soon-tag bds-bg-grays-black bds-pt-4 bds-text-little bds-text-grays-white">Coming soon!</p>
                </div>
                <div class="bds-flexbox bds-flex-col bds-gap-8">
                    <h2 class="bds-text-little">Contact</h2>
                    <p class="bds-text-body-regular">Get in touch with a member of the Block Design System team.</p>
                </div>
            </div>
            <div
                [routerLink]="['/coming-soon']"
                class="bds-col-span-3_md bds-col-span-12_sm bds-flexbox bds-flex-col bds-gap-24">
                <div class="home-media-card-image home-coming-soon bds-flexbox bds-justify-center bds-py-24 bds-bg-global-sand bds-radius-medium">
                    <hrb-illustration data-name="online-assist"></hrb-illustration>
                    <p class="home-coming-soon-tag bds-bg-grays-black bds-pt-4 bds-text-little bds-text-grays-white">Coming soon!</p>
                </div>
                <div class="bds-flexbox bds-flex-col bds-gap-8">
                    <h2 class="bds-text-little">Intake</h2>
                    <p class="bds-text-body-regular">Submit ideas, questions, or fixes to new or existing components.</p>
                </div>
            </div>
        </section>
    </div>

    <!-- Footer -->
    <bdsp-footer></bdsp-footer>
   
  </div>

</bdsp-simple-page>
<bdsp-standard-page>

  <!-- Top Panel -->
  <div class="top-panel">
    <top-nav></top-nav>
  </div>

  <!-- Left Panel -->
  <div class="left-panel">
    <side-nav></side-nav>
  </div>

  <!-- Content Panel -->
  <div class="content-panel">

    <!-- Content Definition -->
    <content-definition
      id="#Definition"
      class="scroll-region">
      <div class="component-name">Input</div>
      <div class="component-tag-name">&lt;hrb-input&gt;</div>
      <div class="component-definition">Inputs display a labelled input and, optionally, associated validation text or hint text.</div>
      <div class="component-figma-link">
        <hrb-link target="_blank" href="https://www.figma.com/design/SjIweQxQcqDzUxbSEiyiQr/%F0%9F%9F%A9-Block-Design-System?m=auto&node-id=6173-62847&t=pazvl9a8B8pVR4ew-1">Open in Figma</hrb-link>
      </div>
      <div class="component-storybook-link">
        <hrb-link href="https://a3pcbdsstr02t.z19.web.core.windows.net/?path=/story/components-forms-input--basic" target="_blank">Open in Storybook</hrb-link>
      </div>
    </content-definition>

    <hrb-rule></hrb-rule>

    <div class="
      bds-pt-64
      ptl-container
      ptl-container-left">

      <bdsp-content-columns>
        <div class="content">

          <!-- Status -->
          <section
            id="#Status"
            class="
              scroll-region
              bds-pb-48
              bds-flexbox
              bds-flex-col
              bds-gap-24">
            <h2 class="bds-text-small">Status</h2>

            <div>
              <div class="labels bds-flexbox bds-flex-row bds-flex-wrap bds-gap-24 bds-mt-16">
                <div class="bds-flexbox bds-gap-8 bds-items-center">
                  <span class="bds-text-little">bds-figma:</span>
                  <hrb-label>Ready for use</hrb-label>
                </div>
                <div class="bds-flexbox bds-gap-8 bds-items-center">
                  <span class="bds-text-little">bds-core:</span>
                  <hrb-label>Ready for use</hrb-label>
                </div>
              </div>
            </div>
          </section>

          <!-- Canvas -->
          <section
            id="#Canvas"
            class="
              scroll-region
              bds-pb-48
              bds-flex
              bds-flex-col
              bds-gap-24">
            <h2 class="bds-text-small">Canvas</h2>
            <div class="bds-bg-grays-gray-100 bds-p-64 bds-radius-medium bds-flexbox bds-justify-center">
              <div class="canvas bds-flexbox bds-flex-col bds-gap-16 bds-justify-center bds-items-center">
                  <hrb-input
                    helper-text="Helper text goes here."
                    label="Label"
                  ></hrb-input>
                </div>
            </div>
          </section>

          <!-- Usage Guidelines -->
          <section
            id="#UsageGuidelines"
            class="
              scroll-region
              bds-pb-48
              bds-flexbox
              bds-flex-col
              bds-gap-24">
            <h2 class="bds-text-small">Usage Guidelines</h2> 
            <p class="bds-text-body-regular">Inputs are found all throughout Block products in forms and other patterns. You may utilize different input types available (e.g., text, email, number, date) to help users input data accurately and enable appropriate browser behaviors.</p>
            <p class="bds-text-body-regular">Use placeholder text to provide additional guidance to users, such as formatting expectations or examples of valid input. However, ensure that the placeholder text does not substitute for a proper label and that it is not mistaken as pre-filled input.</p>
          </section>

          <!-- Behavior -->
          <section
            id="#Behavior"
            class="
              scroll-region
              bds-pb-48
              bds-flexbox
              bds-flex-col
              bds-gap-24">
              <h2 class="bds-text-small">Behavior</h2>
              <p class="bds-text-body-regular">While in the default state, the input should only display the expected user input text. When the input is filled or focused, the label should be shown and should clearly indicate what text is expected for that input. Incorporate validation feedback to inform users about input errors or invalid data. This can include visual cues, such as error messages, error icons, or color changes, to clearly indicate the issue and offer guidance on how to correct it. Disabled inputs cannot be clicked into.</p>
          </section>
          
          <!-- Best Practices -->
          <section
            id="#BestPractices"
            class="
              scroll-region
              bds-pb-48
              bds-flexbox
              bds-flex-col
              bds-gap-24">
              <h2 class="bds-text-small">Best Practices</h2>
              <div class="bds-flexbox bds-flex-col bds-flex-row_md bds-gap-24">
                  <div class="bds-flexbox bds-flex-col bds-gap-24 bds-flex-1">
                      <h3 class="bds-text-small bds-text-global-green">Dos</h3>
                      <div class="bds-bg-grays-gray-100 bds-radius-medium">
                        <hrb-img
                          fit
                          alt="A form with headers and inputs"
                          src="assets/component-details/input/input-do.png"></hrb-img>
                      </div>
                      <ul class="bds-text-body-regular hrb-text--bullet-list bds-pl-24">
                        <li class="bds-body-regular">
                          Change the input text and labels to make what text is expected to be inputted in your form or component.
                        </li>
                        <li class="bds-body-regular">
                          Use help text to show any additional information.
                        </li>
                      </ul>
                  </div>
                  <div class="bds-flexbox bds-flex-col bds-gap-24 bds-flex-1">
                      <h3 class="bds-text-small bds-text-reds-red-400">Don'ts</h3>
                      <div class="bds-bg-grays-gray-100 bds-radius-medium">
                        <hrb-img
                          fit
                          alt="A form with headers and inputs"
                          src="assets/component-details/input/input-do.png"></hrb-img>
                      </div>
                      <ul class="bds-text-body-regular hrb-text--bullet-list bds-pl-24">
                        <li class="bds-body-regular">
                          Change the height of the inputs.
                        </li>
                        <li class="bds-body-regular">
                          Show a validation mark with an error, disabled, or default input.
                        </li>
                      </ul>
                  </div>
              </div>
          </section>

          <!-- Usage Example -->
          <section
            id="#UsageExample"
            class="
              scroll-region
              bds-pb-48
              bds-flexbox
              bds-flex-col
              bds-gap-24">
            <h2 class="bds-text-small">Usage Example</h2>
            <div class="bds-bg-grays-gray-100 bds-p-32 bds-radius-medium bds-flexbox bds-justify-center">
              <hrb-img
                class="bds-mt-16"
                fit
                alt="A form with headers, inputs, and a button"
                src="assets/component-details/input/input-usage-example.png"></hrb-img>
            </div>
          </section>

          <!-- Content Guidelines -->
          <section
            id="#ContentGuidelines"
            class="
              scroll-region
              bds-pb-48
              bds-flexbox
              bds-flex-col
              bds-gap-24">
            <h2 class="bds-text-small">Content Guidelines</h2>
            <p class="bds-text-body-regular">Provide clear and descriptive labels for text inputs to guide users on what information to enter. Utilize placeholder text to offer additional context or examples, but ensure it disappears once the user starts typing.</p>
          </section>

          <!-- Accessibility -->
          <section
            id="#Accessibility"
            class="
              scroll-region
              bds-pb-48
              bds-flexbox
              bds-flex-col
              bds-gap-24">
            <h2 class="bds-text-small">Accessibility</h2>
            <p class="bds-text-body-regular">All text, elements and components must meet accessibility standards. The Web Content Accessibility Guidelines (WCAG 2.2) level AA requires a 4.5:1 color contrast ratio between text and background color for normal text, and 3:1 for large text. Icons and other critical elements should meet this same level of contrast.</p>
          </section>

          <!-- Related -->
          <section
            id="#Related"
            class="
              scroll-region
              bds-pb-48
              bds-flexbox
              bds-flex-col
              bds-gap-24">
              <h2 class="bds-text-small">Related</h2>
              <hrb-link [routerLink]="['/select']">Select</hrb-link>
              <hrb-link [routerLink]="['/textarea']">Textarea</hrb-link>
          </section>
        </div>

        <!-- TOC -->
        <div class="context">
          <toc></toc>
        </div>
        
      </bdsp-content-columns>

    </div>

    <bdsp-footer></bdsp-footer>
   
  </div>

</bdsp-standard-page>
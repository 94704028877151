import { Component } from '@angular/core'

@Component({
  selector: 'coming-soon',
  templateUrl: './coming-soon.component.html',
  styleUrls: ['./coming-soon.component.scss'],
})
export class ComingSoonComponent {
  mobileMenu = {
    topMenu: true,
    getStarted: false,
    foundations: false,
    components: false,
    patterns: false,
    support: false,
  }

  hideMobileSubnav() {
    Object.keys(this.mobileMenu).forEach(x => {
      this.mobileMenu[x] = false
    })
    this.mobileMenu['topMenu'] = true
  }

  showMobileSubnav(text: string) {
    Object.keys(this.mobileMenu).forEach(x => {
      this.mobileMenu[x] = false
    })
    this.mobileMenu[text] = true
  }
}

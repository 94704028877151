<hrb-modal
  #MobileMenu
  id="portal-mobile-menu"
  modal-id="mobile-menu"
  modal-title="Mobile Menu"
  variant="header-empty-title"
  size="large"
  class="hrb-text--body-copy"
>

  <div class="mobile-menu-top">
    <div class="mobile-menu-controls"
      *ngIf="!mobileMenu['topMenu']"
      (click)="hideMobileSubnav()">
      <hrb-icon
      aria-label="Exit Mobile Menu"
      name="chevron-left"></hrb-icon>
      <hrb-text
        style-type="body-copy">
        main menu
      </hrb-text>
    </div>
  </div>

  <div class="mobile-menu-links">
    <div
      id="topMenu"
      *ngIf="mobileMenu['topMenu']"
      class="mobile-menu">
      <hrb-link
        [routerLink]="['../coming-soon']"
        (click)="menu.close()"
        icon="chevron-right"
        icon-position="right"
        theme="primary-black"
        class="mb-sp-24"
        >Get Started</hrb-link>
      <hrb-link
        [routerLink]="['../coming-soon']"
        (click)="menu.close()"
        icon="chevron-right"
        icon-position="right"
        theme="primary-black"
        class="mb-sp-24"
        >Styles</hrb-link>
      <hrb-link
        (click)="showMobileSubnav('components')"
        icon="chevron-right"
        icon-position="right"
        theme="primary-black"
        class="mb-sp-24"
        >Components</hrb-link>
      <hrb-link
        [routerLink]="['../coming-soon']"
        (click)="menu.close()"
        icon="chevron-right"
        icon-position="right"
        theme="primary-black"
        class="mb-sp-24"
        >Patterns</hrb-link>
      <hrb-link
        [routerLink]="['../coming-soon']"
        (click)="menu.close()"
        icon="chevron-right"
        icon-position="right"
        theme="primary-black"
        class="mb-sp-24"
        >Support</hrb-link>
    </div>
    <div
      id="components"
      class="hrb-text--body-copy"
      *ngIf="mobileMenu['components']">
      <hrb-text
        as="h1"
        style-type="medium"
        class="mb-sp-16">Components</hrb-text>
      <hrb-side-nav-item
        [routerLink]="['/components']"
        (click)="menu.close()">Overview</hrb-side-nav-item>
      <hrb-side-nav-item
        [routerLink]="['/accordion']"
        (click)="menu.close()">Accordion</hrb-side-nav-item>
      <hrb-side-nav-item
        [routerLink]="['/banner']"
        (click)="menu.close()">Banner</hrb-side-nav-item>
      <hrb-side-nav-item
        [routerLink]="['/button']"
        (click)="menu.close()">Button</hrb-side-nav-item>
      <hrb-side-nav-item
        [routerLink]="['/card']"
        (click)="menu.close()">Card</hrb-side-nav-item>
      <hrb-side-nav-item
        [routerLink]="['/checkbox']"
        (click)="menu.close()">Checkbox</hrb-side-nav-item>
      <hrb-side-nav-item
        [routerLink]="['/input']"
        (click)="menu.close()">Input</hrb-side-nav-item>
      <hrb-side-nav-item
        [routerLink]="['/label']"
        (click)="menu.close()">Label</hrb-side-nav-item>
      <hrb-side-nav-item
        [routerLink]="['/link']"
        (click)="menu.close()">Link</hrb-side-nav-item>
      <hrb-side-nav-item
        [routerLink]="['/radio']"
        (click)="menu.close()">Radio</hrb-side-nav-item>
      <hrb-side-nav-item
        [routerLink]="['/select']"
        (click)="menu.close()">Select</hrb-side-nav-item>
      <hrb-side-nav-item
        [routerLink]="['/textarea']"
        (click)="menu.close()">Textarea</hrb-side-nav-item>
      <hrb-side-nav-item
        [routerLink]="['/toast']"
        (click)="menu.close()">Toast</hrb-side-nav-item>
      <hrb-side-nav-item
        [routerLink]="['/toggle']"
        (click)="menu.close()">Toggle</hrb-side-nav-item>
    </div>
  </div>

</hrb-modal>
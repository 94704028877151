import { Component, Output, EventEmitter } from '@angular/core'
import { Utilities } from '../../../utilities'

@Component({
  selector: 'content-nav',
  templateUrl: './content-nav.component.html',
})
export class ContentNavComponent {
  constructor(private utilities: Utilities) {}

  @Output() currentTab = new EventEmitter<string>()

  localCurrentTab = 'Overview'

  switchTabs(e: Event) {
    const targetLabel = this.utilities.getLinkLabel(
      (e.target as HTMLElement).innerHTML,
    )
    // Post-MVP: Factor out Portal-specific concerns.
    if (targetLabel.includes('Overview')) this.localCurrentTab = 'Overview'
    if (targetLabel.includes('Angular')) this.localCurrentTab = 'Angular'
    if (targetLabel.includes('Figma')) this.localCurrentTab = 'Figma'
    this.currentTab.emit(this.localCurrentTab)
  }
}

<bdsp-standard-page>

  <!-- Top Panel -->
  <div class="top-panel">
    <top-nav></top-nav>
  </div>

  <!-- Left Panel -->
  <div class="left-panel">
    <side-nav></side-nav>
  </div>

  <!-- Content Panel -->
  <div class="content-panel">

    <!-- Content Definition -->
    <content-definition
      id="#Definition"
      class="scroll-region">
      <div class="component-name">Checkbox</div>
      <div class="component-tag-name">&lt;hrb-checkbox&gt;</div>
      <div class="component-definition">The checkbox component allows users to make multiple selections from a list of options.</div>
      <div class="component-figma-link">
        <hrb-link target="_blank" href="https://www.figma.com/design/SjIweQxQcqDzUxbSEiyiQr/%F0%9F%9F%A9-Block-Design-System?m=auto&node-id=6904-187591&t=pazvl9a8B8pVR4ew-1">Open in Figma</hrb-link>
      </div>
      <div class="component-storybook-link">
        <hrb-link href="https://a3pcbdsstr02t.z19.web.core.windows.net/?path=/story/components-forms-checkbox--basic" target="_blank">Open in Storybook</hrb-link>
      </div>
    </content-definition>

    <hrb-rule></hrb-rule>

    <div class="
      bds-pt-64
      ptl-container
      ptl-container-left">

      <bdsp-content-columns>
        
        <div class="content">

          <!-- Status -->
          <section
            id="#Status"
            class="
              scroll-region
              bds-pb-48
              bds-flexbox
              bds-flex-col
              bds-gap-24">
            <h2 class="bds-text-small">Status</h2>

            <div>
              <h3 class="bds-text-little">Radio Button</h3>
              <div class="labels bds-flexbox bds-flex-row bds-flex-wrap bds-gap-24 bds-mt-16">
                <div class="bds-flexbox bds-gap-8 bds-items-center">
                  <span class="bds-text-little">bds-figma:</span>
                  <hrb-label theme="neon-ba">UX Development</hrb-label>
                </div>
                <div class="bds-flexbox bds-gap-8 bds-items-center">
                  <span class="bds-text-little">bds-core:</span>
                  <hrb-label>Ready for use</hrb-label>
                </div>
              </div>
            </div>

            <div>
              <h3 class="bds-text-little bds-mt-16">Radio Card</h3>
              <div class="labels bds-flexbox bds-flex-row bds-flex-wrap bds-gap-24 bds-gap-16 bds-mt-16">
                <div class="bds-flexbox bds-gap-8 bds-items-center">
                  <span class="bds-text-little">bds-figma:</span>
                  <hrb-label>Ready for use</hrb-label>
                </div>
                <div class="bds-flexbox bds-gap-8 bds-items-center">
                  <span class="bds-text-little">bds-core:</span>
                  <hrb-label>Ready for use</hrb-label>
                </div>
              </div>
            </div>
          </section>

          <!-- Canvas -->
          <section
            id="#Canvas"
            class="
              scroll-region
              bds-pb-48
              bds-flex
              bds-flex-col
              bds-gap-24">
            <h2 class="bds-text-small">Canvas</h2>
            <div class="canvas bds-bg-grays-gray-100 bds-radius-medium bds-flexbox bds-flex-row">
              <div class="bds-flex-1 bds-flexbox bds-flex-col">
                <div class="bds-p-16 bds-bg-grays-gray-200 bds-radius-medium bds-text-little">Checkbox</div>
                <div class="bds-flexbox bds-p-16 bds-justify-center  bds-items-center bds-content-center bds-flex-1">
                  <hrb-checkbox>Checkbox</hrb-checkbox>
                </div>
              </div>
              <div class="bds-flex-1 bds-flexbox bds-flex-col">
                <div class="bds-p-16 bds-bg-grays-gray-200 bds-radius-medium bds-text-little">Checkbox Card</div>
                <div class="bds-flexbox bds-p-16 bds-justify-center  bds-items-center bds-flex-1">
                  <hrb-checkbox data-variant="option-card">Checkbox Card</hrb-checkbox>
                </div>
              </div>
            </div>

            <p class="bds-text-body-regular bds-mt-24">In Figma, we have two separate components: <b>checkbox button</b> and <b>checkbox card</b>.</p>
            <p class="bds-text-body-regular bds-mt-24">In our developed core library, there is one component called <b>hrb-radio-button</b> that can represent either of the above, depending on the value of the 'type' property.</p>
          </section>

          <!-- Usage Guidelines -->
          <section
            id="#UsageGuidelines"
            class="
              scroll-region
              bds-pb-48
              bds-flexbox
              bds-flex-col
              bds-gap-24">
            <h2 class="bds-text-small">Usage Guidelines</h2> 
            <p class="bds-text-body-regular">Use checkboxes (instead of toggles or radio buttons) if multiple options can be selected from a list. The label should be clear and concise with only the first letter of the first word capitalized.</p>
          </section>

          <!-- Behavior -->
          <section
            id="#Behavior"
            class="
              scroll-region
              bds-pb-48
              bds-flexbox
              bds-flex-col
              bds-gap-24">
              <h2 class="bds-text-small">Behavior</h2>
              <ul class="bds-text-body-regular hrb-text--bullet-list bds-pl-24">
                <li class="bds-body-regular">
                  When selected, a checkbox should clearly and instantly communicate its selected state
                </li>
                <li class="bds-body-regular">
                  If used to turn something off or on it should immediately execute that action
                </li>
              </ul>
          </section>
          
          <!-- Best Practices -->
          <section
            id="#BestPractices"
            class="
              scroll-region
              bds-pb-48
              bds-flexbox
              bds-flex-col
              bds-gap-24">
            <h2 class="bds-text-small">Best Practices</h2>
            <div class="bds-flexbox bds-flex-col bds-flex-row_md bds-gap-24">
              <div class="bds-flexbox bds-flex-col bds-gap-24 bds-flex-1">
                <h3 class="bds-text-small bds-text-global-green">Dos</h3>
                <div class="bds-radius-medium">
                  <hrb-img
                    alt="A form with a question, some helper text, and three checkbox options"
                    fit
                    src="assets/component-details/checkbox/checkbox-do.png"></hrb-img>
                </div>
                <ul class="bds-text-body-regular hrb-text--bullet-list bds-pl-24">
                  <li class="bds-body-regular">
                    List options in a logical order such as: likelihood, complexity, risk, etc.
                  </li>
                  <li class="bds-body-regular">
                    If you can't have a list of all possible options, add an "Other" option.
                  </li>
                  <li class="bds-body-regular">
                    If you need an unselected state, add a checkbox group with a "None" option.
                  </li>
                  <li class="bds-body-regular">
                    The label should be scannable.
                  </li>
                </ul>
              </div>
              <div class="bds-flexbox bds-flex-col bds-gap-24 bds-flex-1">
                <h3 class="bds-text-small bds-text-reds-red-400">Don'ts</h3>
                <div class="bds-bg-grays-gray-100 bds-radius-medium">
                  <hrb-img
                    fit
                    alt="A form with a question, some helper text, and three checkbox options"
                    src="assets/component-details/checkbox/checkbox-dont.png"></hrb-img>
                </div>
                <ul class="bds-text-body-regular hrb-text--bullet-list bds-pl-24">
                  <li class="bds-body-regular">
                    List things in alphabetical order because it is language dependent and not localizable.
                  </li>
                </ul>
              </div>
            </div>
          </section>

          <!-- Usage Example -->
          <section
            id="#UsageExample"
            class="
              scroll-region
              bds-pb-48
              bds-flexbox
              bds-flex-col
              bds-gap-24">
            <h2 class="bds-text-small">Usage Example</h2>
            <div class="bds-bg-grays-gray-100 bds-p-32 bds-radius-medium bds-flexbox bds-justify-center">
              <hrb-img
                class="bds-mt-16"
                fit
                alt="A card with a question, some helper text, and three checkbox options"
                src="assets/component-details/checkbox/checkbox-usage-example.png"></hrb-img>
            </div>
          </section>

          <!-- Content Guidelines -->
          <section
            id="#ContentGuidelines"
            class="
              scroll-region
              bds-pb-48
              bds-flexbox
              bds-flex-col
              bds-gap-24">
            <h2 class="bds-text-small">Content Guidelines</h2>
            <p class="bds-text-body-regular">Provide clear and concise labels for checkboxes. Use language that accurately describes the action or item. Checkbox labels should be no longer than a sentence.</p>
          </section>

          <!-- Accessibility -->
          <section
            id="#Accessibility"
            class="
              scroll-region
              bds-pb-48
              bds-flexbox
              bds-flex-col
              bds-gap-24">
            <h2 class="bds-text-small">Accessibility</h2>
            <p class="bds-text-body-regular">All text, elements and components must meet accessibility standards. The Web Content Accessibility Guidelines (WCAG 2.2) level AA requires a 4.5:1 color contrast ratio between text and background color for normal text, and 3:1 for large text. Icons and other critical elements should meet this same level of contrast.</p>
          </section>

          <!-- Related -->
          <section
            id="#Related"
            class="
              scroll-region
              bds-pb-48
              bds-flexbox
              bds-flex-col
              bds-gap-24">
              <h2 class="bds-text-small">Related</h2>
              <hrb-link [routerLink]="['/radio']">Radio</hrb-link>
              <hrb-link [routerLink]="['/button']">Button</hrb-link>
              <hrb-link [routerLink]="['/toggle']">Toggle</hrb-link>
          </section>
        </div>

        <!-- TOC -->
        <div class="context">
          <toc></toc>
        </div>
        
      </bdsp-content-columns>

    </div>

    <bdsp-footer></bdsp-footer>
   
  </div>

</bdsp-standard-page>
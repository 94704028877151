<bdsp-standard-page>

  <!-- Top Panel -->
  <div class="top-panel">
    <top-nav></top-nav>
  </div>

  <!-- Left Panel -->
  <div class="left-panel">
    <side-nav></side-nav>
  </div>

  <!-- Content Panel -->
  <div class="content-panel">

    <div class="ptl-container ptl-container-left bds-py-64">
    <section class="bds-grid">
      <div class="bds-col-span-12">
        <h1 class="bds-text-large">Component Overview</h1>
        <p class="bds-text-body-regular bds-mt-32">
          Block Design System provides a variety of components for building
          larger experiences and patterns. Provided are detailed design and
          development documentation to guide better, more consistent
          experiences.
        </p>
      </div>
    </section>
    </div>

    <hrb-rule></hrb-rule>

    <div class="ptl-container ptl-container-left">
      <div>
        <div class="bds-grid bds-mt-64">
          <hrb-card
            [routerLink]="['/button']"
            class="bds-col-span-12 bds-col-span-6_md bds-col-span-4_lg bds-flexbox bds-flex-col bds-gap-24 bds-mb-32"
          >
            <div
              class="ptl-component-tile-img-container bds-bg-global-sand bds-p-32 bds-radius-small bds-flexbox bds-justify-center"
            >
              <img
                class="bds-self-center"
                src="assets/portal-images/button.png"
                alt=""
              />
            </div>
            <div>
              <h2 class="bds-text-small bds-mt-24 bds-mb-8">Button</h2>
              <p class="bds-text-body-regular bds-text-grays-gray-500">
                Button is used to initiate actions on a page or form.
              </p>
            </div>
          </hrb-card>

          <hrb-card
            [routerLink]="['/card']"
            class="bds-col-span-12 bds-col-span-6_md bds-col-span-4_lg bds-flexbox bds-flex-col bds-gap-24 bds-mb-32"
          >
            <div
              class="ptl-component-tile-img-container bds-bg-global-sand bds-p-32 bds-radius-small bds-flexbox bds-justify-center"
            >
              <img
                class="bds-self-center"
                src="assets/portal-images/card.png"
                alt=""
              />
            </div>
            <div>
              <h2 class="bds-text-small bds-mt-24 bds-my-8">Card</h2>
              <p class="bds-text-body-regular bds-text-grays-gray-500">
                Card is a container that holds other elements within it.
              </p>
            </div>
          </hrb-card>

          <hrb-card
            [routerLink]="['/checkbox']"
            class="bds-col-span-12 bds-col-span-6_md bds-col-span-4_lg bds-flexbox bds-flex-col bds-gap-24 bds-mb-32"
          >
            <div
              class="ptl-component-tile-img-container bds-bg-global-sand bds-p-32 bds-radius-small bds-flexbox bds-justify-center"
            >
              <img
                class="bds-self-center"
                src="assets/portal-images/checkbox.png"
                alt=""
              />
            </div>
            <div>
              <h2 class="bds-text-small bds-mt-24 bds-my-8">Checkbox</h2>
              <p class="bds-text-body-regular bds-text-grays-gray-500">
                Checkbox is a form control for single and multiple selections.
              </p>
            </div>
          </hrb-card>

          <hrb-card
            [routerLink]="['/input']"
            class="bds-col-span-12 bds-col-span-6_md bds-col-span-4_lg bds-flexbox bds-flex-col bds-gap-24 bds-mb-32"
          >
            <div
              class="ptl-component-tile-img-container bds-bg-global-sand bds-p-32 bds-radius-small bds-flexbox bds-justify-center"
            >
              <img
                class="bds-self-center"
                src="assets/portal-images/input.png"
                alt=""
              />
            </div>
            <div>
              <h2 class="bds-text-small bds-mt-24 bds-my-8">Input</h2>
              <p class="bds-text-body-regular bds-text-grays-gray-500">
                Inputs display a labelled input and, optionally, associated
                validation text or hint text.
              </p>
            </div>
          </hrb-card>

          <hrb-card
            [routerLink]="['/radio']"
            class="bds-col-span-12 bds-col-span-6_md bds-col-span-4_lg bds-flexbox bds-flex-col bds-gap-24 bds-mb-32"
          >
            <div
              class="ptl-component-tile-img-container bds-bg-global-sand bds-p-32 bds-radius-small bds-flexbox bds-justify-center"
            >
              <img
                class="bds-self-center"
                src="assets/portal-images/radio.png"
                alt=""
              />
            </div>
            <div>
              <h2 class="bds-text-small bds-mt-24 bds-my-8">Radio</h2>
              <p class="bds-text-body-regular bds-text-grays-gray-500">
                Radio is for making a single selection from a list of
                options.
              </p>
            </div>
          </hrb-card>

          <hrb-card
            [routerLink]="['/select']"
            class="bds-col-span-12 bds-col-span-6_md bds-col-span-4_lg bds-flexbox bds-flex-col bds-gap-24 bds-mb-32"
          >
            <div
              class="ptl-component-tile-img-container bds-bg-global-sand bds-p-32 bds-radius-small bds-flexbox bds-justify-center"
            >
              <img
                class="bds-self-center"
                src="assets/portal-images/select.png"
                alt=""
              />
            </div>
            <div>
              <h2 class="bds-text-small bds-mt-24 bds-my-8">Select</h2>
              <p class="bds-text-body-regular bds-text-grays-gray-500">
                Select is an input for selecting a single option from a menu.
              </p>
            </div>
          </hrb-card>

          <hrb-card
            [routerLink]="['/textarea']"
            class="bds-col-span-12 bds-col-span-6_md bds-col-span-4_lg bds-flexbox bds-flex-col bds-gap-24 bds-mb-32"
          >
            <div
              class="ptl-component-tile-img-container bds-bg-global-sand bds-p-32 bds-radius-small bds-flexbox bds-justify-center"
            >
              <img
                class="bds-self-center"
                src="assets/portal-images/text-area.png"
                alt=""
              />
            </div>
            <div>
              <h2 class="bds-text-small bds-mt-24 bds-my-8">Textarea</h2>
              <p class="bds-text-body-regular bds-text-grays-gray-500">
                Textarea is used to input multiple lines of text.
              </p>
            </div>
          </hrb-card>

          <hrb-card
            [routerLink]="['/accordion']"
            class="bds-col-span-12 bds-col-span-6_md bds-col-span-4_lg bds-flexbox bds-flex-col bds-gap-24 bds-mb-32"
          >
            <div
              class="ptl-component-tile-img-container bds-bg-global-sand bds-p-32 bds-radius-small bds-flexbox bds-justify-center"
            >
              <img
                class="bds-self-center"
                src="assets/portal-images/accordion.png"
                alt=""
              />
            </div>
            <div>
              <h2 class="bds-text-small bds-mt-24 bds-my-8">Accordion</h2>
              <p class="bds-text-body-regular bds-text-grays-gray-500">
                Accordion optimizes screen real estate by allowing users to expand and collapse sections.
              </p>
            </div>
          </hrb-card>

          <hrb-card
            [routerLink]="['/toast']"
            class="bds-col-span-12 bds-col-span-6_md bds-col-span-4_lg bds-flexbox bds-flex-col bds-gap-24 bds-mb-32"
          >
            <div
              class="ptl-component-tile-img-container bds-bg-global-sand bds-p-32 bds-radius-small bds-flexbox bds-justify-center"
            >
              <img
                class="bds-self-center"
                src="assets/portal-images/toast.png"
                alt=""
              />
            </div>
            <div>
              <h2 class="bds-text-small bds-mt-24 bds-my-8">Toast</h2>
              <p class="bds-text-body-regular bds-text-grays-gray-500">
                Toast is a short, time-based message that provides non-disruptive information.
              </p>
            </div>
          </hrb-card>

          <hrb-card
            [routerLink]="['/link']"
            class="bds-col-span-12 bds-col-span-6_md bds-col-span-4_lg bds-flexbox bds-flex-col bds-gap-24 bds-mb-32"
          >
            <div
              class="ptl-component-tile-img-container bds-bg-global-sand bds-p-32 bds-radius-small bds-flexbox bds-justify-center"
            >
              <img
                class="bds-self-center"
                src="assets/portal-images/link.png"
                alt=""
              />
            </div>
            <div>
              <h2 class="bds-text-small bds-mt-24 bds-my-8">Link</h2>
              <p class="bds-text-body-regular bds-text-grays-gray-500">
                A link is text that a user clicks to go to a different website, page, section, or to trigger a dialog.
              </p>
            </div>
          </hrb-card>

          <hrb-card
            [routerLink]="['/toggle']"
            class="bds-col-span-12 bds-col-span-6_md bds-col-span-4_lg bds-flexbox bds-flex-col bds-gap-24 bds-mb-32"
          >
            <div
              class="ptl-component-tile-img-container bds-bg-global-sand bds-p-32 bds-radius-small bds-flexbox bds-justify-center"
            >
              <img
                class="bds-self-center"
                src="assets/portal-images/toggle.png"
                alt=""
              />
            </div>
            <div>
              <h2 class="bds-text-small bds-mt-24 bds-my-8">Toggle</h2>
              <p class="bds-text-body-regular bds-text-grays-gray-500">
                A toggle represents a physical switch that allows someone to choose between two mutually exclusive options.
              </p>
            </div>
          </hrb-card>

          <hrb-card
            [routerLink]="['/label']"
            class="bds-col-span-12 bds-col-span-6_md bds-col-span-4_lg bds-flexbox bds-flex-col bds-gap-24 bds-mb-32"
          >
            <div
              class="ptl-component-tile-img-container bds-bg-global-sand bds-p-32 bds-radius-small bds-flexbox bds-justify-center"
            >
              <img
                class="bds-self-center"
                src="assets/portal-images/label.png"
                alt=""
              />
            </div>
            <div>
              <h2 class="bds-text-small bds-mt-24 bds-my-8">Label</h2>
              <p class="bds-text-body-regular bds-text-grays-gray-500">
                The label is designed to visually represent and categorize information within our interfaces.
              </p>
            </div>
          </hrb-card>
        </div>
      </div>
    </div>

    <bdsp-footer></bdsp-footer>
   
  </div>

</bdsp-standard-page>
<bdsp-standard-page>

  <!-- Top Panel -->
  <div class="top-panel">
    top panel
  </div>

  <!-- Left Panel -->
  <div class="left-panel">
    left panel
  </div>

  <!-- Content Panel -->
  <div class="content-panel">
    content panel
  </div>

</bdsp-standard-page>
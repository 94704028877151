<bdsp-standard-page>

  <!-- Top Panel -->
  <div class="top-panel">
    <top-nav></top-nav>
  </div>

  <!-- Left Panel -->
  <div class="left-panel">
    <side-nav></side-nav>
  </div>

  <!-- Content Panel -->
  <div class="content-panel">

    <!-- Content Definition -->
    <content-definition
      id="#Definition"
      class="scroll-region">
      <div class="component-name">Link</div>
      <div class="component-tag-name">&lt;hrb-link&gt;</div>
      <div class="component-definition">A link is text that a user clicks to go to a different website, page, section or to trigger a dialog. Links are underlined, colored, or emphasized to differentiate them from the surrounding copy.</div>
      <div class="component-figma-link">
        <hrb-link target="_blank" href="https://www.figma.com/design/SjIweQxQcqDzUxbSEiyiQr/%F0%9F%9F%A9-Block-Design-System?m=auto&node-id=6821-231141&t=pazvl9a8B8pVR4ew-1">Open in Figma</hrb-link>
      </div>
      <div class="component-storybook-link">
        <hrb-link href="https://a3pcbdsstr02t.z19.web.core.windows.net/?path=/story/components-links--basic" target="_blank">Open in Storybook</hrb-link>
      </div>
    </content-definition>

    <hrb-rule></hrb-rule>

    <div class="
      bds-pt-64
      ptl-container
      ptl-container-left">

      <bdsp-content-columns>
        
        <div class="content">

          <!-- Status -->
          <section
            id="#Status"
            class="
              scroll-region
              bds-pb-48
              bds-flexbox
              bds-flex-col
              bds-gap-24">
            <h2 class="bds-text-small">Status</h2>

            <div>
              <div class="labels bds-flexbox bds-flex-row bds-flex-wrap bds-gap-24 bds-mt-16">
                <div class="bds-flexbox bds-gap-8 bds-items-center">
                  <span class="bds-text-little">bds-figma:</span>
                  <hrb-label>Ready for use</hrb-label>
                </div>
                <div class="bds-flexbox bds-gap-8 bds-items-center">
                  <span class="bds-text-little">bds-core:</span>
                  <hrb-label>Ready for use</hrb-label>
                </div>
              </div>
            </div>
          </section>

          <!-- Canvas -->
          <section
            id="#Canvas"
            class="
              scroll-region
              bds-pb-48
              bds-flex
              bds-flex-col
              bds-gap-24">
            <h2 class="bds-text-small">Canvas</h2>
            <div class="bds-bg-grays-gray-100 bds-p-64 bds-radius-medium bds-flexbox bds-justify-center">
              <div class="canvas bds-flexbox bds-flex-col bds-gap-16 bds-justify-center bds-items-center">
                  <hrb-link [routerLink]="['/link']">Link text</hrb-link>
                </div>
            </div>
          </section>

          <!-- Usage Guidelines -->
          <section
            id="#UsageGuidelines"
            class="
              scroll-region
              bds-pb-48
              bds-flexbox
              bds-flex-col
              bds-gap-24">
            <h2 class="bds-text-small">Usage Guidelines</h2> 
            <p class="bds-text-body-regular">Use inline text links within bodies of copy or as tertiary links in flows (i.e. "Skip this” or "learn more”). Use standalone links as CTAs in secondary modules when a button would be too prominent.</p>
          </section>

          <!-- Behavior -->
          <section
            id="#Behavior"
            class="
              scroll-region
              bds-pb-48
              bds-flexbox
              bds-flex-col
              bds-gap-24">
              <h2 class="bds-text-small">Behavior</h2>
              <ul class="bds-text-body-regular hrb-text--bullet-list bds-pl-24">
                <li class="bds-body-regular">
                  Links go places.
                </li>
                <li class="bds-body-regular">
                  Buttons trigger actions/functionality. 
                </li>
              </ul>
              <p class="bds-text-body-regular">However, there are times when it makes sense for a link to look like a button. In that case, there are additional themes for buttons, currently available in the code, that will do this. They are not represented as Variants in bds-figma. This is because it would be redundant. However, it's good for the designer to know these themes are available for links when working with their dev partners.</p>
          </section>
          
          <!-- Best Practices -->
          <section
            id="#BestPractices"
            class="
              scroll-region
              bds-pb-48
              bds-flexbox
              bds-flex-col
              bds-gap-24">
            <h2 class="bds-text-small">Best Practices</h2>
            <div class="bds-flexbox bds-flex-col bds-flex-row_md bds-gap-24">
              <div class="bds-flexbox bds-flex-col bds-gap-24 bds-flex-1">
                <h3 class="bds-text-small bds-text-global-green">Dos</h3>
                <div class="bds-radius-medium">
                  <hrb-img
                    alt="A button and a link"
                    fit
                    src="assets/component-details/link/link-do.png"></hrb-img>
                </div>
                <ul class="bds-text-body-regular hrb-text--bullet-list bds-pl-24">
                  <li class="bds-body-regular">
                    Do keep Text Link phrases as short as possible.
                  </li>
                </ul>
              </div>
              <div class="bds-flexbox bds-flex-col bds-gap-24 bds-flex-1">
                <h3 class="bds-text-small bds-text-reds-red-400">Don'ts</h3>
                <div class="bds-bg-grays-gray-100 bds-radius-medium">
                  <hrb-img
                    fit
                    alt="A card with content, including a link with long text"
                    src="assets/component-details/link/link-dont.png"></hrb-img>
                </div>
                <ul class="bds-text-body-regular hrb-text--bullet-list bds-pl-24">
                  <li class="bds-body-regular">
                    Don't underline an entire sentence. Text Links should be concise while letting the user know what they would be opening.
                  </li>
                  <li class="bds-body-regular">
                    Don't use the Text Button as the primary action when it's coupled with a Standard Button. 
                  </li>
                  <li class="bds-body-regular">
                    Don't display with a shadow.
                  </li>
                  <li class="bds-body-regular">
                    Don't push the icon below the content.
                  </li>
                </ul>
              </div>
            </div>
          </section>

          <!-- Usage Example -->
          <section
            id="#UsageExample"
            class="
              scroll-region
              bds-pb-48
              bds-flexbox
              bds-flex-col
              bds-gap-24">
            <h2 class="bds-text-small">Usage Example</h2>
            <div class="bds-bg-grays-gray-100 bds-p-32 bds-radius-medium bds-flexbox bds-justify-center">
              <hrb-img
                class="bds-mt-16"
                fit
                alt="A page in a linear flow with a link at the bottom"
                src="assets/component-details/link/link-usage-example.png"></hrb-img>
            </div>
          </section>

          <!-- Content Guidelines -->
          <section
            id="#ContentGuidelines"
            class="
              scroll-region
              bds-pb-48
              bds-flexbox
              bds-flex-col
              bds-gap-24">
            <h2 class="bds-text-small">Content Guidelines</h2>
            <p class="bds-text-body-regular">Provide clear and concise text for links. Keep link messages or CTAs only a few words long.</p>
          </section>

          <!-- Accessibility -->
          <section
            id="#Accessibility"
            class="
              scroll-region
              bds-pb-48
              bds-flexbox
              bds-flex-col
              bds-gap-24">
            <h2 class="bds-text-small">Accessibility</h2>
            <p class="bds-text-body-regular">All text, elements and components must meet accessibility standards. The Web Content Accessibility Guidelines (WCAG 2.2) level AA requires a 4.5:1 color contrast ratio between text and background color for normal text, and 3:1 for large text. Icons and other critical elements should meet this same level of contrast.</p>
          </section>

          <!-- Related -->
          <section
            id="#Related"
            class="
              scroll-region
              bds-pb-48
              bds-flexbox
              bds-flex-col
              bds-gap-24">
              <h2 class="bds-text-small">Related</h2>
              <hrb-link [routerLink]="['/button']">Button</hrb-link>
              <hrb-link [routerLink]="['/label']">Label</hrb-link>
          </section>
        </div>

        <!-- TOC -->
        <div class="context">
          <toc></toc>
        </div>
        
      </bdsp-content-columns>

    </div>

    <bdsp-footer></bdsp-footer>
   
  </div>

</bdsp-standard-page>
export const environment = {
  ldClientSideId: '#{ldClientSideId}#',
  get ldCsId() {
    return this.ldClientSideId.includes('ldClientSideId')
      ? '64356e0a657b5813c5e4eb7e'
      : this.ldClientSideId
  },
  // dev cms
  cmsUrl: 'https://bds-cms-dev-t.a3d0dvbdsase01t.appserviceenvironment.net',
}

// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

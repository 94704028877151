import { Component, OnInit } from '@angular/core'
import { ViewportScroller } from '@angular/common'
import { Utilities } from '../../../utilities'
import { ScrollService } from '@app/shared/services/scroll.service'

@Component({
  selector: 'toc',
  templateUrl: './toc.component.html',
})
export class TocComponent implements OnInit {
  constructor(
    private utilities: Utilities,
    private viewportScroller: ViewportScroller,
    private scrollService: ScrollService,
  ) {
    scrollService.currentSection.subscribe(res => {
      this.currentTocLink = res
    })
  }

  tocLinks = [
    {
      label: 'Definition',
      id: '#Definition',
    },
    {
      label: 'Status',
      id: '#Status',
    },
    {
      label: 'Canvas',
      id: '#Canvas',
    },
    {
      label: 'Usage Guidelines',
      id: '#UsageGuidelines',
    },
    {
      label: 'Behavior',
      id: '#Behavior',
    },
    {
      label: 'Best Practices',
      id: '#BestPractices',
    },
    {
      label: 'Usage Example',
      id: '#UsageExample',
    },
    {
      label: 'Content Guidelines',
      id: '#ContentGuidelines',
    },
    {
      label: 'Accessibility',
      id: '#Accessibility',
    },
    {
      label: 'Related',
      id: '#Related',
    },
  ]

  currentTocLink = '#Definition'

  ngOnInit() {
    this.viewportScroller.setOffset([0, 120])
    this.currentTocLink = this.tocLinks[0].id
  }

  scrollTo(e: Event) {
    const targetLabel = this.utilities.getLinkLabel(
      (e.target as HTMLElement).innerHTML,
    )
    this.viewportScroller.scrollToAnchor('#' + targetLabel)
    this.currentTocLink = targetLabel
  }
}

import { Injectable } from '@angular/core'
import { CmsResponseModel } from '../app/shared/models/data.models'

@Injectable()
// Injectable class to maintain pseudo state like behavior
export class GlobalCmsResponseManager {
  // Properties //

  /*
        Global state variable for the cms response.
        Using dependency injection, this property can be accessed by other components.
        This eliminates the need to make individual CmsService calls every time we navigate to that component.
    */
  private __GLOBAL_CMS_RESPONSE__: CmsResponseModel

  // Methods //
  public __SET_GLOBAL_CMS_RESPONSE__(value: CmsResponseModel) {
    this.__GLOBAL_CMS_RESPONSE__ = value
  }

  public __GET_GLOBAL_CMS_RESPONSE__() {
    return this.__GLOBAL_CMS_RESPONSE__
  }
}

<bdsp-simple-page>

  <!-- Top Panel -->
  <div class="top-panel">
    top panel
  </div>

  <!-- Content Panel -->
  <div class="content-panel">
    content panel
  </div>

</bdsp-simple-page>
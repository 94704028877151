<hrb-nav-secondary>
  <hrb-nav-secondary-item
    (click)="switchTabs($event)"
    [attr.is-current]="localCurrentTab === 'Overview'"
    as="button"
    link-text="Overview"
    ></hrb-nav-secondary-item>
  <hrb-nav-secondary-item
    (click)="switchTabs($event)"
    [attr.is-current]="localCurrentTab === 'Angular'"
    as="button"
    link-text="Angular"
    ></hrb-nav-secondary-item>
  <hrb-nav-secondary-item
    (click)="switchTabs($event)"
    [attr.is-current]="localCurrentTab === 'Figma'"
    as="button"
    link-text="Figma"
    ></hrb-nav-secondary-item>
</hrb-nav-secondary>
<!-- 
  ACCESSIBILITY: Keyboard user need the ability to skip to the main content.  Due to Angular, we enalbe this with a skip button rather than the more conventional skip link.
 -->
<rie-skip-button></rie-skip-button>


<!-- ******************** -->
<!-- START: MVP Structure -->
<router-outlet></router-outlet>
<!-- END:   MVP Structure -->
<!-- ******************** -->


<!--
  LOADER: The page loader hides UI flicker on load, including from LaunchDarkly.
-->
<div class="loader" *ngIf="showLoader" @showHide></div>


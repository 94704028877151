import { BrowserModule, Title } from '@angular/platform-browser'
import { BrowserAnimationsModule } from '@angular/platform-browser/animations'
import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core'
import { FormsModule, ReactiveFormsModule } from '@angular/forms'
import { HttpClientModule } from '@angular/common/http'

// BDS Dependencies
import { defineCustomElements } from '@bds/bds-core/loader'
import { BdsNgModule } from '@bds/bds-ng'

/*******************************/
/************ MVP **************/

// Home
import { HomeComponent } from './home/home.component'

// Components
import { ComponentsComponent } from './components/components.component'
import { DefinitionComponent } from './components/shared/content-definition/content-definition.component'
import { ButtonPageComponent } from './components/button/button.component'
import { InputPageComponent } from './components/input/input.component'
import { CardPageComponent } from './components/card/card.component'
import { SelectPageComponent } from './components/select/select.component'
import { TextareaPageComponent } from './components/textarea/textarea.component'
import { RadioPageComponent } from './components/radio/radio.component'
import { CheckboxPageComponent } from './components/checkbox/checkbox.component'
import { BannerPageComponent } from './components/banner/banner.component'
import { AccordionPageComponent } from './components/accordion/accordion.component'
import { ToastPageComponent } from './components/toast/toast.component'
import { LinkPageComponent } from './components/link/link.component'
import { TogglePageComponent } from './components/toggle/toggle.component'
import { LabelPageComponent } from './components/label/label.component'

// --> Shared
import { ContentNavComponent } from './components/shared/content-nav/content-nav.component'
import { TocComponent } from './components/shared/toc/toc.component'

// Patterns
import { StandardPageComponent } from './patterns/standard-page/standard-page.component'

// Shared
import { TopNavComponent } from './shared/top-nav/top-nav.component'
import { SideNavComponent } from './shared/side-nav/side-nav.component'
import { MobileMenuComponent } from './shared/mobile-menu/mobile-menu.component'
import { ComingSoonComponent } from './shared/coming-soon/coming-soon.component'
// --> BDS Patterns
import { BdspSimplePageComponent } from './shared/bdsp/bdsp-simple-page/bdsp-simple-page.component'
import { BdspStandardPageComponent } from './shared/bdsp/bdsp-standard-page/bdsp-standard-page.component'
import { BdspContentColumnsComponent } from './shared/bdsp/bdsp-content-columns/bdsp-content-columns.component'
import { BdspFooterComponent } from './shared/bdsp/bdsp-footer/bdsp-footer.component'

// In Development
import { ComponentDetailsComponent } from './components/component-details/component-details.component'
import { ShowcaseComponent } from './showcase/showcase.component'
import { CopilotComponent } from './copilot/copilot.component'
import { SandboxComponent } from './sandbox/sandbox.component'
import { BwoSandboxComponent } from './sandbox/bwo/bwo.component'

// Root
import { RieComponent } from './rie.component'
import { RieRoutingModule } from './rie-routing.module'
import { GlobalCmsResponseManager } from '../global/global_cms_manager'
import { CmsService } from './shared/services/cms.service'
import { SimplePageComponent } from './patterns/simple-page/simple-page.component'

@NgModule({
  declarations: [
    RieComponent,
    HomeComponent,
    ComponentsComponent,
    BdspStandardPageComponent,
    ButtonPageComponent,
    StandardPageComponent,
    TopNavComponent,
    SideNavComponent,
    DefinitionComponent,
    MobileMenuComponent,
    ComingSoonComponent,
    ComponentDetailsComponent,
    ContentNavComponent,
    BdspContentColumnsComponent,
    TocComponent,
    BdspFooterComponent,
    InputPageComponent,
    CardPageComponent,
    SelectPageComponent,
    TextareaPageComponent,
    RadioPageComponent,
    CheckboxPageComponent,
    BannerPageComponent,
    AccordionPageComponent,
    ToastPageComponent,
    LinkPageComponent,
    TogglePageComponent,
    LabelPageComponent,
    ShowcaseComponent,
    SandboxComponent,
    BwoSandboxComponent,
    BdspSimplePageComponent,
    SimplePageComponent,
    CopilotComponent,
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    RieRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    BdsNgModule,
  ],
  providers: [Title, GlobalCmsResponseManager, CmsService],
  bootstrap: [RieComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class RieModule {
  constructor() {
    defineCustomElements(window)
  }
}

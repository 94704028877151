<div class="ptl-container ptl-container-left bds-py-64">
  <section class="bds-grid">
    <div class="bds-col-span-12">
      <h1 class="bds-text-large">
        <ng-content select=".component-name"></ng-content>
      </h1>
      <p class="bds-text-little hrb-color--grey bds-mb-32">
        <ng-content select=".component-tag-name"></ng-content>
      </p>
      <p class="bds-text-body-regular bds-mb-32">
        <ng-content select=".component-definition"></ng-content>
      </p>
      <p class="bds-text-body-regular bds-flexbox bds-gap-16">
        <ng-content select=".component-figma-link"></ng-content>
        <ng-content select=".component-storybook-link"></ng-content>
      </p>
    </div>
  </section>
</div>
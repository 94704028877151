<bdsp-simple-page>

  <!-- Top Panel -->
  <div class="top-panel">
    <top-nav></top-nav>
  </div>

  <!-- Content Panel -->
  <div class="content-panel">

    <!-- Content -->
    <div class="
      bds-p-64
      bds-flexbox
      bds-flex-row
      bds-justify-center">
      <div class="bds-text-jumbo">
        Co-pilot
      </div>
    </div>
   
  </div>

</bdsp-simple-page>
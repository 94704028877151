<bdsp-simple-page>

    <!-- Top Panel -->
    <div class="top-panel">
        <top-nav></top-nav>
    </div>

    <!-- Content Panel -->
    <div class="
        content-panel
        bds-flexbox
        bds-flex-col
        bds-justify-center">

        <div class="
            bds-flexbox
            bds-justify-center
            bds-p-64">
            <hrb-card
                theme="medium-light-yellow"
                class="
                    coming-soon-card
                    bds-radius-large">
                <div class="bds-p-24">
                    <div class="
                        bds-flexbox
                        bds-justify-center">
                        <div class="
                            coming-soon-illustration
                            bds-mb-24">
                            <hrb-illustration data-name="heart"></hrb-illustration>
                        </div>
                    </div>
                    <h2 class="bds-text-medium bds-mb-8">Coming soon!</h2>
                    <p class="bds-text-body-regular">Introducing an exciting addition to our BDS website! We are currently working on a new page that will enhance your user experience. Stay tuned for updates, as we can't wait to unveil this valuable resource to assist you in creating seamless designs. Keep an eye out for the launch announcement, coming soon!</p>
                </div>
            </hrb-card>
        </div>

        <!-- Footer -->
        <bdsp-footer></bdsp-footer>

    </div>

</bdsp-simple-page>
import {
  Component,
  OnInit,
  ChangeDetectorRef,
  ViewEncapsulation,
} from '@angular/core'
import { LaunchDarklyService } from '@app/launchdarkly.service'
import { CmsItem } from '@app/shared/models/data.models'
import { GlobalCmsResponseManager } from 'src/global/global_cms_manager'
import { DomSanitizer, SafeHtml } from '@angular/platform-browser'

@Component({
  selector: 'component-details-page',
  templateUrl: './component-details.component.html',
  encapsulation: ViewEncapsulation.None,
})
export class ComponentDetailsComponent implements OnInit {
  global_cms_manager: GlobalCmsResponseManager
  canvasExampleHtml: SafeHtml
  dosListHtml: SafeHtml
  dontsListHtml: SafeHtml
  relatedLink: string
  subscriptionForDevelopers
  showForDevelopers
  public cmsItem: CmsItem

  constructor(
    private cdr: ChangeDetectorRef,
    private ld: LaunchDarklyService,
    _global_cms_manager: GlobalCmsResponseManager,
    private sanitizer: DomSanitizer,
  ) {
    this.global_cms_manager = _global_cms_manager
  }

  ngOnInit() {
    // set the global cms response for button

    this.cmsItem = this.global_cms_manager
      .__GET_GLOBAL_CMS_RESPONSE__()
      .items.find(_ => _.properties.definitionTitle == 'Button')

    // sanitize the html from umbraco
    this.canvasExampleHtml = this.getSanitizedHtml(
      this.cmsItem.properties.componentExampleS.replace(/\n/g, ''),
    )
    this.dosListHtml = this.getSanitizedHtml(
      this.cmsItem.properties.dosList.replace(/\n/g, ''),
    )
    this.dontsListHtml = this.getSanitizedHtml(
      this.cmsItem.properties.dontsList.replace(/\n/g, ''),
    )
    this.relatedLink = this.cmsItem.properties.relatedComponentLink[0].url

    this.showForDevelopers = this.ld.flags['for-developers']
    this.cdr.detectChanges()
    this.subscriptionForDevelopers = this.ld.flagChange.subscribe(flags => {
      this.showForDevelopers = flags['for-developers'].current
    })
    this.ld.ldClient.on('ready', () => {
      this.showForDevelopers = this.ld.flags['for-developers']
    })
    // assign data for this mock component
  }

  getSanitizedHtml(html: string): SafeHtml {
    return this.sanitizer.bypassSecurityTrustHtml(html)
  }
}

import { Component } from '@angular/core'

@Component({
  selector: 'bdsp-footer',
  templateUrl: './bdsp-footer.component.html',
  styleUrls: ['./bdsp-footer.component.scss'],
})
export class BdspFooterComponent {
  currentYear = new Date().getFullYear()
}

<header class="
  top-nav
  bds-flexbox
  bds-items-center
  bds-gap-48
  bds-py-16
  bds-px-24">

  <!-- Mobile Menu -->
  <hrb-icon
    data-micromodal-trigger="mobile-menu"
    class="mobile-menu-icon"
    aria-label="Show Mobile Menu"
    name="menu"
    ></hrb-icon>

  <!-- Logo -->
  <div class="branding">
    <hrb-link
      [routerLink]="['/']">
      <hrb-img class="logo" alt="Block Design System" fit src="assets/Block-Logo.svg"></hrb-img>
    </hrb-link>
  </div>

  <!-- Links -->
  <div class="top-nav-links">
    <hrb-nav-secondary>
      <hrb-nav-secondary-item
        link-text="Get Started"
        [routerLink]="['/coming-soon']"
      ></hrb-nav-secondary-item>
      <hrb-nav-secondary-item
        link-text="Styles"
        [routerLink]="['/coming-soon']"
      ></hrb-nav-secondary-item>
      <hrb-nav-secondary-item
        link-text="Components"
        [routerLink]="['/components']"
      ></hrb-nav-secondary-item>
      <hrb-nav-secondary-item
        link-text="Patterns"
        [routerLink]="['/coming-soon']"
      ></hrb-nav-secondary-item>
      <hrb-nav-secondary-item
        *ngIf="showForDevelopers"
        link-text="Showcase"
        [routerLink]="['/showcase']"
      ></hrb-nav-secondary-item>
      <hrb-nav-secondary-item
        *ngIf="showForDevelopers"
        link-text="Co-pilot"
        [routerLink]="['/copilot']"
      ></hrb-nav-secondary-item>
      <hrb-nav-secondary-item
        *ngIf="showForDevelopers"
        link-text="Sandbox"
        [routerLink]="['/sandbox']"
      ></hrb-nav-secondary-item>
      <hrb-nav-secondary-item
        link-text="Support"
        [routerLink]="['/coming-soon']"
      ></hrb-nav-secondary-item>
    </hrb-nav-secondary>
  </div>

</header>
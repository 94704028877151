<bdsp-standard-page>

  <!-- Top Panel -->
  <div class="top-panel">
    <top-nav></top-nav>
  </div>

  <!-- Left Panel -->
  <div class="left-panel">
    <side-nav></side-nav>
  </div>

  <!-- Content Panel -->
  <div class="content-panel">

    <!-- Content Definition -->
    <content-definition
      id="#Definition"
      class="scroll-region">
      <div class="component-name">{{ cmsItem.properties.definitionTitle }}</div>
      <div class="component-tag-name">{{ cmsItem.properties.tagName }}</div>
      <div class="component-definition">{{ cmsItem.properties.definitionDescription }}</div>
      <div class="component-figma-link">
        <hrb-link target="_blank" href="{{cmsItem.properties.figmaLink[0].url}}">Open in Figma</hrb-link>
      </div>
      <div class="component-storybook-link">
        <hrb-link href="{{cmsItem.properties.storybookLink[0].url}}" target="_blank">Open in Storybook</hrb-link>
      </div>
    </content-definition>

    <hrb-rule></hrb-rule>

    <div class="
      bds-pt-64
      ptl-container
      ptl-container-left">


      <bdsp-content-columns>
        
        <div class="content">

           <!-- Canvas -->
          <section
            id="#Canvas"
            class="
              scroll-region
              bds-pb-48
              bds-flex
              bds-flex-col
              bds-gap-24">
            <h2 class="bds-text-small">Canvas</h2>
            <div class="bds-bg-grays-gray-100 bds-p-64 bds-radius-medium bds-flexbox bds-justify-center">
              <div id="{{cmsItem.properties.canvasID}}" class="canvas bds-flexbox bds-flex-col bds-gap-16 bds-justify-center bds-items-center" [innerHTML]="canvasExampleHtml"></div>
            </div>
          </section>

          <!-- Usage Guidelines -->
          <section
            id="#UsageGuidelines"
            class="
              scroll-region
              bds-pb-48
              bds-flexbox
              bds-flex-col
              bds-gap-24">
            <h2 class="bds-text-small">Usage Guidelines</h2> 
            <p class="bds-text-body-regular">{{cmsItem.properties.usageGuidelinesText}}</p>
          </section>

          <!-- Behavior -->
          <section
            id="#Behavior"
            class="
              scroll-region
              bds-pb-48
              bds-flexbox
              bds-flex-col
              bds-gap-24">
              <h2 class="bds-text-small">Behavior</h2>
              <p class="bds-text-body-regular">{{cmsItem.properties.usageGuidelinesText}}</p>
          </section>
          
          <!-- Best Practices -->
          <section
            id="#BestPractices"
            class="
              scroll-region
              bds-pb-48
              bds-flexbox
              bds-flex-col
              bds-gap-24">
            <h2 class="bds-text-small">Best Practices</h2>
            <div class="bds-flexbox bds-flex-col bds-flex-row_md bds-gap-24">
              <div class="bds-flexbox bds-flex-col bds-gap-24 bds-flex-1">
                <h3 class="bds-text-small bds-text-global-green">Dos</h3>
                <div class="ptl-directionCard ptl-directionCard--do bds-bg-grays-gray-100 bds-radius-medium">
                  <hrb-img
                    alt="{{ cmsItem.properties.dosImageAlt }}"
                    fit
                    src="{{ cmsItem.properties.dosImage[0].url}}"></hrb-img>
                </div>
                <div [innerHTML]="dosListHtml"></div>
              </div>
              <div class="bds-flexbox bds-flex-col bds-gap-24 bds-flex-1">
                <h3 class="bds-text-small bds-text-reds-red-400">Don'ts</h3>
                <div class="ptl-directionCard ptl-directionCard--doNot bds-bg-grays-gray-100 bds-radius-medium">
                  <hrb-img
                    fit
                    alt="{{ cmsItem.properties.dontsImageAlt }}"
                    src="{{ cmsItem.properties.dontsImage[0].url}}"></hrb-img>
                </div>
                <div [innerHTML]="dontsListHtml"></div>
              </div>
            </div>
          </section>

          <!-- Usage Example -->
          <section
            id="#UsageExample"
            class="
              scroll-region
              bds-pb-48
              bds-flexbox
              bds-flex-col
              bds-gap-24">
            <h2 class="bds-text-small">Usage Example</h2>
            <div class="bds-bg-grays-gray-100 bds-p-32 bds-radius-medium bds-flexbox bds-flex-col bds-gap-32">
              <div class="bds-flexbox bds-flex-col bds-flex-row_lg bds-gap-32 bds-flex-1">
                <div>
                  <h3 class="bds-text-little">{{ cmsItem.properties.usageExample1Header }}</h3>
                  <hrb-img
                    class="bds-mt-16"
                    fit
                    alt="A card with a header, a primary button, and secondary button"
                    src="{{ cmsItem.properties.usageExample1Image[0].url }}"></hrb-img>
                </div>
                <div>
                  <h3 class="bds-text-little">{{ cmsItem.properties.usageExample2Header }}</h3>
                  <hrb-img
                    class="bds-mt-16"
                    fit
                    alt="A card with a header and a primary button"
                    src="{{ cmsItem.properties.usageExample2Image[0].url }}"></hrb-img>
                </div>
              </div>
              <div class="bds-flexbox bds-flex-col bds-flex-row_lg bds-gap-32 bds-flex-1">
                <div>
                  <h3 class="bds-text-little">{{ cmsItem.properties.usageExample3Header }}</h3>
                  <hrb-img
                    class="bds-mt-16"
                    fit
                    alt="A card with a header and a primary button"
                    src="{{ cmsItem.properties.usageExample3Image[0].url }}"></hrb-img>
                </div>
                <div>
                  <h3 class="bds-text-little">{{ cmsItem.properties.usageExample4Header }}</h3>
                  <hrb-img
                    class="bds-mt-16"
                    fit
                    alt="A card with a header, a primary button, and secondary button"
                    src="{{ cmsItem.properties.usageExample4Image[0].url }}"></hrb-img>
                </div>
              </div>
            </div>
          </section>

          <!-- Content Guidelines -->
          <section
            id="#ContentGuidelines"
            class="
              scroll-region
              bds-pb-48
              bds-flexbox
              bds-flex-col
              bds-gap-24">
            <h2 class="bds-text-small">Content Guidelines</h2>
            <p class="bds-text-body-regular">{{ cmsItem.properties.contentGuidelinesTextContent }}</p>
          </section>

          <!-- Accessibility -->
          <section
            id="#Accessibility"
            class="
              scroll-region
              bds-pb-48
              bds-flexbox
              bds-flex-col
              bds-gap-24">
              <h2 class="bds-text-small">Accessibility</h2>
              <h3 class="bds-text-little">Design recommendations</h3>
              <p class="bds-text-body-regular">{{ cmsItem.properties.designRecommendationsText}}</p>
              <h3 class="bds-text-little">Development recommendations</h3>
              <p class="bds-text-body-regular">{{ cmsItem.properties.developmentRecommendations}}</p>
          </section>

          <!-- Related -->
          <section
            id="#Related"
            class="
              scroll-region
              bds-pb-48
              bds-flexbox
              bds-flex-col
              bds-gap-24">
              <h2 class="bds-text-small">Related</h2>
              <hrb-link [routerLink]="relatedLink">{{cmsItem.properties.relatedComponentLink[0].title}}</hrb-link>
          </section>
        </div>

        <!-- TOC -->
        <div class="context">
          <toc></toc>
        </div>
        
      </bdsp-content-columns>

    </div>

    <bdsp-footer></bdsp-footer>
   
  </div>

</bdsp-standard-page>
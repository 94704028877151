<bdsp-standard-page>

  <!-- Top Panel -->
  <div class="top-panel">
    <top-nav></top-nav>
  </div>

  <!-- Left Panel -->
  <div class="left-panel">
    <side-nav></side-nav>
  </div>

  <!-- Content Panel -->
  <div class="content-panel">

    <!-- Content Definition -->
    <content-definition
      id="#Definition"
      class="scroll-region">
      <div class="component-name">Button</div>
      <div class="component-tag-name">&lt;hrb-button&gt;</div>
      <div class="component-definition">Buttons trigger events or actions that are used as part of larger components or patterns.</div>
      <div class="component-figma-link">
        <hrb-link target="_blank" href="https://www.figma.com/design/SjIweQxQcqDzUxbSEiyiQr/%F0%9F%9F%A9-Block-Design-System?m=auto&node-id=6370-69645&t=T4cy4kiSHGopOODF-1">Open in Figma</hrb-link>
      </div>
      <div class="component-storybook-link">
        <hrb-link href="https://a3pcbdsstr02t.z19.web.core.windows.net/?path=/story/components-buttons-buttons-basic--button" target="_blank">Open in Storybook</hrb-link>
      </div>
    </content-definition>

    <hrb-rule></hrb-rule>

    <div class="
      bds-pt-64
      ptl-container
      ptl-container-left">

      <bdsp-content-columns>
        
        <div class="content">

          <!-- Status -->
          <section
            id="#Status"
            class="
              scroll-region
              bds-pb-48
              bds-flexbox
              bds-flex-col
              bds-gap-24">
            <h2 class="bds-text-small">Status</h2>

            <div>
              <h3 class="bds-text-little">Primary</h3>
              <div class="labels bds-flexbox bds-flex-row bds-flex-wrap bds-gap-24 bds-mt-16">
                <div class="bds-flexbox bds-gap-8 bds-items-center">
                  <span class="bds-text-little">bds-figma:</span>
                  <hrb-label>Ready for use</hrb-label>
                </div>
                <div class="bds-flexbox bds-gap-8 bds-items-center">
                  <span class="bds-text-little">bds-core:</span>
                  <hrb-label theme="gray-100">Updates coming soon</hrb-label>
                </div>
              </div>
            </div>

            <div>
              <h3 class="bds-text-little bds-mt-16">Secondary</h3>
              <div class="labels bds-flexbox bds-flex-row bds-flex-wrap bds-gap-24 bds-gap-16 bds-mt-16">
                <div class="bds-flexbox bds-gap-8 bds-items-center">
                  <span class="bds-text-little">bds-figma:</span>
                  <hrb-label>Ready for use</hrb-label>
                </div>
                <div class="bds-flexbox bds-gap-8 bds-items-center">
                  <span class="bds-text-little">bds-core:</span>
                  <hrb-label theme="gray-100">Updated coming soon</hrb-label>
                </div>
              </div>
            </div>
          </section>

          <!-- Canvas -->
          <section
            id="#Canvas"
            class="
              scroll-region
              bds-pb-48
              bds-flex
              bds-flex-col
              bds-gap-24">
            <h2 class="bds-text-small">Canvas</h2>
            <div class="bds-bg-grays-gray-100 bds-p-64 bds-radius-medium bds-flexbox bds-justify-center">
              <div class="canvas bds-flexbox bds-flex-col bds-gap-16 bds-justify-center bds-items-center">
                    <hrb-button full-width>button</hrb-button>
                    <hrb-button full-width theme="secondary">button</hrb-button>
                </div>
            </div>
          </section>

          <!-- Usage Guidelines -->
          <section
            id="#UsageGuidelines"
            class="
              scroll-region
              bds-pb-48
              bds-flexbox
              bds-flex-col
              bds-gap-24">
            <h2 class="bds-text-small">Usage Guidelines</h2> 
            <p class="bds-text-body-regular">Buttons are both the signposts and pathways through our digital experiences that communicate actions that users can take. The button's label or text description indicates the purpose of the action to the user. Establish a hierarchy for different types of buttons. Use primary buttons for the most important or prominent actions, and secondary buttons for less significant actions. Use tertiary buttons for actions with lower priority.</p>
          </section>

          <!-- Behavior -->
          <section
            id="#Behavior"
            class="
              scroll-region
              bds-pb-48
              bds-flexbox
              bds-flex-col
              bds-gap-24">
              <h2 class="bds-text-small">Behavior</h2>
              <p class="bds-text-body-regular">The button component has three states, static, hover, and disabled. The main action occurs when the button is clicked.</p>
          </section>
          
          <!-- Best Practices -->
          <section
            id="#BestPractices"
            class="
              scroll-region
              bds-pb-48
              bds-flexbox
              bds-flex-col
              bds-gap-24">
            <h2 class="bds-text-small">Best Practices</h2>
            <div class="bds-flexbox bds-flex-col bds-flex-row_md bds-gap-24">
              <div class="bds-flexbox bds-flex-col bds-gap-24 bds-flex-1">
                <h3 class="bds-text-small bds-text-global-green">Dos</h3>
                <div class="bds-radius-medium">
                  <hrb-img
                    alt="A card with a header, a primary button, and secondary button"
                    fit
                    src="assets/component-details/button/button-do.png"></hrb-img>
                </div>
                <ul class="bds-text-body-regular hrb-text--bullet-list bds-pl-24">
                  <li class="bds-body-regular">
                    Have the button in a clear and obvious location that makes sense in the user flow
                  </li>
                  <li class="bds-body-regular">
                    Capitalize the first letter of the first word in the button label
                  </li>
                </ul>
              </div>
              <div class="bds-flexbox bds-flex-col bds-gap-24 bds-flex-1">
                <h3 class="bds-text-small bds-text-reds-red-400">Don'ts</h3>
                <div class="bds-bg-grays-gray-100 bds-radius-medium">
                  <hrb-img
                    fit
                    alt="A card with a header and two primary buttons"
                    src="assets/component-details/button/button-dont.png"></hrb-img>
                </div>
                <ul class="bds-text-body-regular hrb-text--bullet-list bds-pl-24">
                  <li class="bds-body-regular">
                    Have more than one primary button on the same page
                  </li>
                  <li class="bds-body-regular">
                    Make the button label too lengthy - two words max is best
                  </li>
                </ul>
              </div>
            </div>
          </section>

          <!-- Usage Example -->
          <section
            id="#UsageExample"
            class="
              scroll-region
              bds-pb-48
              bds-flexbox
              bds-flex-col
              bds-gap-24">
            <h2 class="bds-text-small">Usage Example</h2>
            <div class="bds-bg-grays-gray-100 bds-p-32 bds-radius-medium bds-flexbox bds-flex-col bds-gap-32">
              <div class="bds-flexbox bds-flex-col bds-flex-row_lg bds-gap-32 bds-flex-1">
                <div>
                  <h3 class="bds-text-little">Default Colors on Light Background</h3>
                  <hrb-img
                    class="bds-mt-16"
                    fit
                    alt="A primary button, a secondary button, and a link"
                    src="assets/component-details/button/button-default-on-light.png"></hrb-img>
                </div>
                <div>
                  <h3 class="bds-text-little">Default Colors on Dark Background</h3>
                  <hrb-img
                    class="bds-mt-16"
                    fit
                    alt="A primary button, a secondary button, and a link"
                    src="assets/component-details/button/button-default-on-dark.png"></hrb-img>
                </div>
              </div>
              <div class="bds-flexbox bds-flex-col bds-flex-row_lg bds-gap-32 bds-flex-1">
                <div>
                  <h3 class="bds-text-little">Neutral Colors on Light Background</h3>
                  <hrb-img
                    class="bds-mt-16"
                    fit
                    alt="A primary button, a secondary button, and a link"
                    src="assets/component-details/button/button-neutral-on-light.png"></hrb-img>
                </div>
                <div>
                  <h3 class="bds-text-little">Neutral Colors on Dark Background</h3>
                  <hrb-img
                    class="bds-mt-16"
                    fit
                    alt="A primary button, a secondary button, and a link"
                    src="assets/component-details/button/button-neutral-on-dark.png"></hrb-img>
                </div>
              </div>
            </div>
          </section>

          <!-- Content Guidelines -->
          <section
            id="#ContentGuidelines"
            class="
              scroll-region
              bds-pb-48
              bds-flexbox
              bds-flex-col
              bds-gap-24">
            <h2 class="bds-text-small">Content Guidelines</h2>
            <p class="bds-text-body-regular">Provide clear and concise labels for buttons. Use language that accurately describes the action or function associated with the button. There should be no more than two words used inside a button component.</p>
          </section>

          <!-- Accessibility -->
          <section
            id="#Accessibility"
            class="
              scroll-region
              bds-pb-48
              bds-flexbox
              bds-flex-col
              bds-gap-24">
            <h2 class="bds-text-small">Accessibility</h2>
            <h3 class="bds-text-little">Design recommendations</h3>
            <p class="bds-text-body-regular">All text, elements and components must meet accessibility standards. The Web Content Accessibility Guidelines (WCAG 2.2) level AA requires a 4.5:1 color contrast ratio between text and background color for normal text, and 3:1 for large text. Icons and other critical elements should meet this same level of contrast.</p>
            <h3 class="bds-text-little">Development recommendations</h3>
            <p class="bds-text-body-regular">When Button text does not provide sufficient context about the Button's behavior, supply a short, descriptive label for screen-readers using accessibilityLabel.</p>
          </section>

          <!-- Related -->
          <section
            id="#Related"
            class="
              scroll-region
              bds-pb-48
              bds-flexbox
              bds-flex-col
              bds-gap-24">
              <h2 class="bds-text-small">Related</h2>
              <hrb-link [routerLink]="['/link']">Link</hrb-link>
          </section>
        </div>

        <!-- TOC -->
        <div class="context">
          <toc></toc>
        </div>
        
      </bdsp-content-columns>

    </div>

    <bdsp-footer></bdsp-footer>
   
  </div>

</bdsp-standard-page>
<div id="bdsp-standard-page" class="bdsp-standard-page">

  <!-- Top Panel-->
  <div id="top-panel" class="top-panel bds-elevation-1">
    <ng-content select=".top-panel"></ng-content>
  </div>

  <div class="content-row bds-flexbox bds-flex-row">

    <!-- Left Panel -->
    <div id="left-panel" class="left-panel bds-bg-grays-white">
      <ng-content select=".left-panel"></ng-content>
    </div>

    <!-- Content Panel -->
    <div id="content-panel" class="content-panel bds-bg-grays-white bds-grow-1">
      <ng-content select=".content-panel"></ng-content>
    </div>

  </div>

  <mobile-menu></mobile-menu>

</div>
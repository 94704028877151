import { Injectable } from '@angular/core'
import { Router } from '@angular/router'

@Injectable({ providedIn: 'root' })
export class Utilities {
  constructor(private router: Router) {}

  // MVP - Remove when static pages are removed.
  navigate(route: string) {
    this.router.navigate([route])
  }

  getLinkLabel(label: string) {
    return label.replace('<!---->', '').replace(/\s/g, '')
  }
}

<bdsp-standard-page>

  <!-- Top Panel -->
  <div class="top-panel">
    <top-nav></top-nav>
  </div>

  <!-- Left Panel -->
  <div class="left-panel">
    <side-nav></side-nav>
  </div>

  <!-- Content Panel -->
  <div class="content-panel">

    <!-- Content Definition -->
    <content-definition
      id="#Definition"
      class="scroll-region">
      <div class="component-name">Accordion</div>
      <div class="component-tag-name">&lt;hrb-accordion&gt;</div>
      <div class="component-definition">The accordion component serves the purpose of optimizing screen real estate and organizing content by allowing users to expand and collapse sections of information.</div>
      <div class="component-figma-link">
        <hrb-link href="https://www.figma.com/design/SjIweQxQcqDzUxbSEiyiQr/%F0%9F%9F%A9-Block-Design-System?m=auto&node-id=6925-184070&t=pazvl9a8B8pVR4ew-1" target="_blank">Open in Figma</hrb-link>
      </div>
      <div class="component-storybook-link">
        <hrb-link href="https://a3pcbdsstr02t.z19.web.core.windows.net/?path=/story/components-accordion-accordion-component--accordion-example" target="_blank">Open in Storybook</hrb-link>
      </div>
    </content-definition>

    <hrb-rule></hrb-rule>

    <div class="
      bds-pt-64
      ptl-container
      ptl-container-left">

      <bdsp-content-columns>
        
        <div class="content">

          <!-- Status -->
          <section
            id="#Status"
            class="
              scroll-region
              bds-pb-48
              bds-flexbox
              bds-flex-col
              bds-gap-24">
            <h2 class="bds-text-small">Status</h2>
            <div>
              <div class="labels bds-flexbox bds-flex-row bds-flex-wrap bds-gap-24 bds-gap-16 bds-mt-16">
                <div class="bds-flexbox bds-gap-8 bds-items-center">
                  <span class="bds-text-little">bds-figma:</span>
                  <hrb-label>Ready for use</hrb-label>
                </div>
                <div class="bds-flexbox bds-gap-8 bds-items-center">
                  <span class="bds-text-little">bds-core:</span>
                  <hrb-label>Ready for use</hrb-label>
                </div>
              </div>
            </div>
          </section>

          <!-- Canvas -->
          <section
            id="#Canvas"
            class="
              scroll-region
              bds-pb-48
              bds-flex
              bds-flex-col
              bds-gap-24">
            <h2 class="bds-text-small">Canvas</h2>
            <div class="bds-bg-grays-gray-100 bds-p-64 bds-radius-medium bds-flexbox bds-justify-center">
              <div class="canvas bds-flexbox bds-flex-col bds-gap-16 bds-justify-center">
                <hrb-accordion type="content">
                  <hrb-accordion-item header-text="Section 1 Title">
                    <div class="bds-pb-16 bds-pb-24 bds-text-body-regular">
                      Section content
                    </div>
                  </hrb-accordion-item>
                  <hrb-accordion-item header-text="Section 2 Title">
                    <div class="bds-pb-16 bds-pb-24 bds-text-body-regular">
                      Section content
                    </div>
                  </hrb-accordion-item>
                </hrb-accordion>
              </div>
            </div>
          </section>

          <!-- Usage Guidelines -->
          <section
            id="#UsageGuidelines"
            class="
              scroll-region
              bds-pb-48
              bds-flexbox
              bds-flex-col
              bds-gap-24">
            <h2 class="bds-text-small">Usage Guidelines</h2> 
            <p class="bds-text-body-regular">Ensure a clear and logical organization of content within the accordion sections. Use descriptive and concise labels for accordion sections to guide users effectively. Maintain a uniform visual style for the accordion component across different platforms and devices.</p>
          </section>

          <!-- Behavior -->
          <section
            id="#Behavior"
            class="
              scroll-region
              bds-pb-48
              bds-flexbox
              bds-flex-col
              bds-gap-24">
              <h2 class="bds-text-small">Behavior</h2>
              <ul class="bds-text-body-regular hrb-text--bullet-list bds-pl-24">
                <li class="bds-body-regular">
                  Accordions should expand when the downward-facing chevron on the right is selected.
                </li>
                <li class="bds-body-regular">
                  Accordions should close when the upward-facing chevron on the right is selected.
                </li>
              </ul>
          </section>
          
          <!-- Best Practices -->
          <section
            id="#BestPractices"
            class="
              scroll-region
              bds-pb-48
              bds-flexbox
              bds-flex-col
              bds-gap-24">
            <h2 class="bds-text-small">Best Practices</h2>
            <div class="bds-flexbox bds-flex-col bds-flex-row_md bds-gap-24">
              <div class="bds-flexbox bds-flex-col bds-gap-24 bds-flex-1">
                <h3 class="bds-text-small bds-text-global-green">Dos</h3>
                <div class="bds-radius-medium">
                  <hrb-img
                    alt="A card with an accordion"
                    fit
                    src="assets/component-details/accordion/accordion-do.png"></hrb-img>
                </div>
                <ul class="bds-text-body-regular hrb-text--bullet-list bds-pl-24">
                  <li class="bds-body-regular">
                    Allow the accordion to stretch horizontally to fill a container.
                  </li>
                </ul>
              </div>
              <div class="bds-flexbox bds-flex-col bds-gap-24 bds-flex-1">
                <h3 class="bds-text-small bds-text-reds-red-400">Don'ts</h3>
                <div class="bds-bg-grays-gray-100 bds-radius-medium">
                  <hrb-img
                    fit
                    alt="A card with an accordion in tight padding"
                    src="assets/component-details/accordion/accordion-dont.png"></hrb-img>
                </div>
                <ul class="bds-text-body-regular hrb-text--bullet-list bds-pl-24">
                  <li class="bds-body-regular">
                    Overuse accordions in your designs. They should be used sparingly. Hiding content from a user is not the ideal way to simplify a screen.
                  </li>
                  <li class="bds-body-regular">
                    Use to display essential information.
                  </li>
                </ul>
              </div>
            </div>
          </section>

          <!-- Usage Example -->
          <section
            id="#UsageExample"
            class="
              scroll-region
              bds-pb-48
              bds-flexbox
              bds-flex-col
              bds-gap-24">
            <h2 class="bds-text-small">Usage Example</h2>
            <div class="bds-bg-grays-gray-100 bds-p-32 bds-radius-medium bds-flexbox bds-justify-center">
              <hrb-img
                class="bds-mt-16"
                fit
                alt="A card with a header and an accordion containing four sections"
                src="assets/component-details/accordion/accordion-usage-example.png"></hrb-img>
            </div>
          </section>

          <!-- Content Guidelines -->
          <section
            id="#ContentGuidelines"
            class="
              scroll-region
              bds-pb-48
              bds-flexbox
              bds-flex-col
              bds-gap-24">
            <h2 class="bds-text-small">Content Guidelines</h2>
            <p class="bds-text-body-regular">Provide clear and descriptive text for the accordion labels. Let users know what information they might be able to find within each accordion.</p>
          </section>

          <!-- Accessibility -->
          <section
            id="#Accessibility"
            class="
              scroll-region
              bds-pb-48
              bds-flexbox
              bds-flex-col
              bds-gap-24">
            <h2 class="bds-text-small">Accessibility</h2>
            <p class="bds-text-body-regular">All text, elements and components must meet accessibility standards. The Web Content Accessibility Guidelines (WCAG 2.2) level AA requires a 4.5:1 color contrast ratio between text and background color for normal text, and 3:1 for large text. Icons and other critical elements should meet this same level of contrast.</p>
          </section>

          <!-- Related -->
          <section
            id="#Related"
            class="
              scroll-region
              bds-pb-48
              bds-flexbox
              bds-flex-col
              bds-gap-24">
            <h2 class="bds-text-small">Related</h2>
            <hrb-link [routerLink]="['/button']">Button</hrb-link>
            <hrb-link [routerLink]="['/card']">Card</hrb-link>
          </section>
        </div>

        <!-- TOC -->
        <div class="context">
          <toc></toc>
        </div>
        
      </bdsp-content-columns>

    </div>

    <bdsp-footer></bdsp-footer>
   
  </div>

</bdsp-standard-page>